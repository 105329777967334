import React, { useState, Fragment, useEffect, Suspense } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import { endpoint } from "../utils/constants";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { numberWithCommas } from "../utils/constants";
import product from "../assets/img/product.png";
import floppy from "../assets/img/floppy.png";
import plusimg from "../assets/img/plus.png";
import door from "../assets/img/door.png";
// import { animateScroll as scroll } from "react-scroll";
import useViewport from "../utils/useViewport";
import { motion } from "framer-motion";
import { BoxesIcon } from "../3d/boxes-icon";
import plus from "../assets/img/plus.png";
import { useAspect } from "@react-three/drei";
import { compose } from "@reduxjs/toolkit";

const Items = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openStockModal, setOpenStockModal] = useState(false);

  const [imgPreview, setImgPreview] = useState("");
  const [img2Preview, setImg2Preview] = useState("");
  const [img3Preview, setImg3Preview] = useState("");
  const [img4Preview, setImg4Preview] = useState("");
  const [img5Preview, setImg5Preview] = useState("");
  const [action, setAction] = useState("edit");

  const { page, search } = useParams();
  const user = useSelector((state) => state.main.user);
  const [isFetching, setIsFetching] = useState(false);

  const [items, setItems] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [searchItemTypes, setSearchItemTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [groups, setGroups] = useState([]);
  const [measurementUnities, setMeasurementUnities] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);


  const [itemId, setItemId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [web, setWeb] = useState("");
  const [webDescription, setWebDescription] = useState("");
  const [fiscalDescription, setFiscalDescription] = useState("");
  const [webSpecification, setWebSpecification] = useState("");
  const [factoryDescription, setFactoryDescription] = useState("");
  const [barcode, setBarcode] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [broad, setBroad] = useState("");
  const [depth, setDepth] = useState("");
  const [NCM, setNCM] = useState("");
  const [extent, setExtent] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [model, setModel] = useState("");
  const [colours, setColours] = useState("");
  const [iva, setIva] = useState("");
  const [internalcode, setInternalCode] = useState("");
  const [factorycode, setFactoryCode] = useState("");
  const [price, setPrice] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");
  const [costFob, setCostFob] = useState("");
  const [medCostFob, setMedCostFob] = useState("");
  const [costCif, setCostCif] = useState("");
  const [medCostCif, setMedCostCif] = useState("");
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [image2Preview, setImage2Preview] = useState("");
  const [image3Preview, setImage3Preview] = useState("");
  const [image4Preview, setImage4Preview] = useState("");
  const [image5Preview, setImage5Preview] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [image2File, setImage2File] = useState([]);
  const [image3File, setImage3File] = useState([]);
  const [image4File, setImage4File] = useState([]);
  const [image5File, setImage5File] = useState([]);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [itemTypeId, setItemTypeId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [measurementUnityId, setMeasurementUnityId] = useState(0);
  const [currencyId, setCurrencyId] = useState(0);
  const [lastBuy, setLastBuy] = useState("");
  const [lastSell, setLastSell] = useState("");
  const [observations, setObservations] = useState("");
  const [promotion, setPromotion] = useState(0);
  const [website, setWebsite] = useState(0);
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [searchText, setSearchText] = useState(search || "");
  const { width } = useViewport();
  const [newBrand, setNewBrand] = useState('');
  const [showNewBrandInput, setShowNewBrandInput] = useState(false);
  const [newItemType, setNewItemType] = useState('');
  const [showNewItemTypeInput, setShowNewItemTypeInput] = useState(false);
  const [newGroup, setNewGroup] = useState('');
  const [showNewGroupInput, setShowNewGroupInput] = useState(false);
  const [newMeasurementUnity, setNewMeasurementUnity] = useState('');
  const [showNewMeasurementUnityInput, setShowNewMeasurementUnityInput] = useState(false);

  const [boole, setBoole] = useState(0);

  async function fetchItems() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let items = await axiosAuth.get(
      "/items?page=" + pageQuery + "&search=" + search + "&boole=" + 1
    );
    console.log(items);

    if (items.data.error) {
      if (items.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setItems(items.data.items.rows);
    setTotal(items.data.items.count);
    setPages(items.data.items.pages);

    setCompanies(items.data.companies);
    setItemTypes(items.data.itemtypes);
    setSearchItemTypes(items.data.itemtypes);
    items.data.itemtypes.push({
      id: 'add',
      name: 'Crear tipo de articulo',

    });
    //console.log(items.data.itemtypes);
    setBrands(items.data.brands);
    items.data.brands.push({
      id: 'add',
      name: 'Crear Marca',

    });
    // console.log(items.data.brands);

    setGroups(items.data.groups);
    items.data.groups.push({
      id: 'add',
      name: 'Crear grupo',

    });
    //console.log(items.data.groups);
    setMeasurementUnities(items.data.measurementunities);
    setCurrencies(items.data.currencies);
  }
  async function fetchDeposits() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let deposits = await axiosAuth.get("/deposits?page=" + pageQuery);

    setIsFetching(false);

    if (deposits.data.error) {
      if (deposits.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setDeposits(deposits.data.deposits.rows);

  }

  useEffect(() => {
    fetchDeposits();
    fetchItems();
    dispatch(setTitle('Articulos'));
  }, []);
  useEffect(() => {
    //fetchItems();
  }, [showNewBrandInput]);

  useEffect(() => {
    fetchItems();
  }, [location]);
  const viewStock = (itemId) => {
    // Buscar el ítem directamente en la lista actual de `items`.
    const item = items.find((i) => i.id === itemId);
    if (item) {
      setSelectedItem(item); // Configurar el ítem seleccionado.
      setOpenStockModal(true); // Abrir el modal.
    } else {
      console.error("Item not found in the current list.");
    }
  };

  const getDepositName = (depositId) => {
    const deposit = deposits.find((d) => d.id === depositId);
    return deposit ? deposit.description : "No encontrado";
  };

  const calculateTotalStock = () => {
    return selectedItem?.stocks?.reduce((total, stock) => total + stock.existence, 0) || 0;
  };

  const editItem = (itemId) => {
    setAction("edit");
    setItemId(itemId);
    setCompanyId(user.companyId);

    let item = items.filter((item) => item.id === itemId)[0];
    // console.log(item);

    setName(item.name);
    setDescription(item.description);
    setWeb(item.web);
    setFiscalDescription(item.fiscalDescription);
    setWebDescription(item.webDescription);
    setWebSpecification(item.webSpecification);
    setImage(item.image);
    setImagePreview(prev =>
      item.image == "" ? "" : endpoint + "itemImages/" + item.image
    );
    setBarcode(item.barcode);
    setInternalCode(item.internalCode);
    setFactoryCode(item.factoryCode);
    setIva(item.iva);
    setPrice(numberWithCommas(item.price));
    setPrice2(numberWithCommas(item.price2));
    setPrice3(numberWithCommas(item.price3));
    setCostFob(numberWithCommas(item.costFob));
    setMedCostFob(numberWithCommas(item.medCostFob));
    setCostCif(numberWithCommas(item.costCif));
    setMedCostCif(numberWithCommas(item.medCostCif));
    setCompanyId(item.companyId);
    setItemTypeId(item.itemTypeId);
    setBrandId(item.brandId);
    setGroupId(item.groupId);
    setMeasurementUnityId(item.measurementUnityId);
    setPromotion(item.promotion);
    setWebsite(item.website);
    setWeight(item.weight);
    setHeight(item.height);
    setBroad(item.broad);
    setDepth(item.depth);
    setNCM(item.NCM);
    setCurrencyId(item.currencyId);
    setObservations(item.observations);
    setActive(item.active);
    setDeleted(item.deleted);
    setLastBuy(item.lastBuy);
    setLastSell(item.lastSell);

    setOpen(true);

  
  };

  const clearFields = () => {
    setItemId(0);
    setName("");
    setWeb("");
    setDescription("");
    setWebDescription("");
    setFiscalDescription("");
    setWebSpecification("");
    setBarcode("");
    setInternalCode("");
    setFactoryCode("");
    setPrice("");
    setPrice2("");
    setPrice3("");
    setCostFob("");
    setMedCostFob("");
    setCostCif("");
    setMedCostCif("");
    setIva("");
    setItemTypeId(0);
    setBrandId(0);
    setGroupId(0);
    setMeasurementUnityId(0);
    setCurrencyId(0);
    setObservations("");
    setImage("");
    setImagePreview("");
    setImageFile(null);
    setImage2("");
    setImage2Preview("");
    setImage2File(null);
    setImage3("");
    setImage3Preview("");
    setImage3File(null);
    setImage4("");
    setImage4Preview("");
    setImage4File(null);
    setImage5("");
    setImage5Preview("");
    setImage5File(null);
    setPromotion(0);
    setWebsite(0);
    setWeight("");
    setHeight("");
    setBroad("");
    setDepth("");
    setNCM("");
    setActive(0);
    setDeleted(0);
    setLastBuy("");
    setLastSell("");
  };

  const editItem2 = (itemId) => {
    setAction("edit");
    setItemId(itemId);
    setCompanyId(user.companyId);

    let item = items.filter((item) => item.id === itemId)[0];
    console.log(items);
    setName(item.name);
    setDescription(item.description);
    setWeb(item.web);
    setFiscalDescription(item.fiscalDescription);
    setWebDescription(item.webDescription);
    setWebSpecification(item.webSpecification);
    setImage(item.image);
    setImagePreview(prev =>
      item.image == "" ? "" : endpoint + "itemImages/" + item.image
    );
    setBarcode(item.barcode);
    setInternalCode(item.internalCode);
    setFactoryCode(item.factoryCode);
    setIva(item.iva);
    setPrice(numberWithCommas(item.price));
    setPrice2(numberWithCommas(item.price2));
    setPrice3(numberWithCommas(item.price3));
    setCostFob(numberWithCommas(item.costFob));
    setMedCostFob(numberWithCommas(item.medCostFob));
    setCostCif(numberWithCommas(item.costCif));
    setMedCostCif(numberWithCommas(item.medCostCif));
    setCompanyId(item.companyId);
    setItemTypeId(item.itemtypeId);
    setBrandId(item.brandId);
    setGroupId(item.groupId);
    setMeasurementUnityId(item.measurementUnityId);
    setPromotion(item.promotion);
    setWebsite(item.website);
    setWeight(item.weight);
    setHeight(item.height);
    setBroad(item.broad);
    setDepth(item.depth);
    setNCM(item.NCM);
    setCurrencyId(item.currencyId);
    setObservations(item.observations);
    setActive(item.active);
    //setDeleted(item.deleted);
    setLastBuy(item.lastBuy);
    setLastSell(item.lastSell);

    setOpen2(true);
    // scroll.scrollToTop();{
  };

  const clearFields2 = () => {
    setItemId(0);
    setName("");
    setWeb("");
    setDescription("");
    setWebDescription("");
    setFiscalDescription("");
    setWebSpecification("");
    setBarcode("");
    setInternalCode("");
    setFactoryCode("");
    setPrice("");
    setPrice2("");
    setPrice3("");
    setCostFob("");
    setMedCostFob("");
    setCostCif("");
    setMedCostCif("");
    setIva("");
    setItemTypeId(0);
    setBrandId(0);
    setGroupId(0);
    setMeasurementUnityId(0);
    setCurrencyId(0);
    setObservations("");
    setImage("");
    setImagePreview("");
    setImageFile(null);
    setImage2("");
    setImage2Preview("");
    setImage2File(null);
    setImage3("");
    setImage3Preview("");
    setImage3File(null);
    setImage4("");
    setImage4Preview("");
    setImage4File(null);
    setImage5("");
    setImage5Preview("");
    setImage5File(null);
    setPromotion(0);
    setWebsite(0);
    setWeight("");
    setHeight("");
    setBroad("");
    setDepth("");
    setNCM("");
    setActive(0);
    setDeleted(0);
    setLastBuy("");
    setLastSell("");
  };

  const handleSubmit = async () => {

    if (
      name === "" ||
      measurementUnityId == 0 ||
      measurementUnityId == "" ||
      currencyId == 0 ||
      currencyId == "" ||
      companyId == 0 ||
      companyId == "" ||
      itemTypeId == 0 ||
      itemTypeId == "" ||
      brandId == 0 ||
      brandId == "" ||
      groupId == 0 ||
      groupId == "" ||
      price == 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = itemId;
      let newItemsObj = [];

      let formData = new FormData();
      formData.append("id", id);
      formData.append("file", imageFile);
      formData.append("file2", image2File);
      formData.append("file3", image3File);
      formData.append("file4", image4File);
      formData.append("file5", image5File);
      formData.append("image", image);
      formData.append("image2", image2);
      formData.append("image3", image3);
      formData.append("image4", image4);
      formData.append("image5", image5);
      formData.append("name", name);
      formData.append("webSpecification", webSpecification);
      formData.append("description", description);
      formData.append("webDescription", webDescription);
      formData.append("fiscalDescription", fiscalDescription);
      formData.append("web", web);
      formData.append("barcode", barcode);
      formData.append("internalCode", internalcode);
      formData.append("factoryCode", factorycode);
      formData.append("iva", iva);
      formData.append("price", String(price).replaceAll(",", ""));
      formData.append("price2", String(price2).replaceAll(",", ""));
      formData.append("price3", String(price3).replaceAll(",", ""));
      formData.append("costFob", String(costFob).replaceAll(",", ""));
      formData.append("medCostFob", String(medCostFob).replaceAll(",", ""));
      formData.append("costCif", String(costCif).replaceAll(",", ""));
      formData.append("medCostCif", String(medCostCif).replaceAll(",", ""));
      formData.append("companyId", companyId);
      formData.append("itemTypeId", itemTypeId);
      formData.append("brandId", brandId);
      formData.append("groupId", groupId);
      formData.append("promotion", promotion);
      formData.append("weight", weight);
      formData.append("height", height);
      formData.append("broad", broad);
      formData.append("depth", depth);
      formData.append("website", website);
      formData.append("NCM", NCM);
      formData.append("measurementUnityId", measurementUnityId);
      formData.append("currencyId", currencyId);
      formData.append("observations", observations);
      formData.append("active", active);
      formData.append("deleted", deleted);

      let updateItemRequest = await axiosAuth.put("/items", formData);

      if (updateItemRequest.data.error) {
        if (updateItemRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      items.map((item) => {
        if (item.id == id) {
          newItemsObj.push(updateItemRequest.data);
        } else {
          newItemsObj.push(item);
        }
        return "";
      });


      setItems(newItemsObj);
    } else {
      let formData = new FormData();
      formData.append("file", imageFile);
      formData.append("file2", image2File);
      formData.append("file3", image3File);
      formData.append("file4", image4File);
      formData.append("file5", image5File);
      formData.append("image", image);
      formData.append("image2", image2);
      formData.append("image3", image3);
      formData.append("image4", image4);
      formData.append("image5", image5);
      formData.append("name", name);
      formData.append("webSpecification", webSpecification);
      formData.append("description", description);
      formData.append("fiscalDescription", fiscalDescription);
      formData.append("web", web);
      formData.append("webDescription", webDescription);
      formData.append("barcode", barcode);
      formData.append("internalCode", internalcode);
      formData.append("factoryCode", factorycode);
      formData.append("iva", iva);
      formData.append("price", String(price).replaceAll(",", ""));
      formData.append("price2", String(price2).replaceAll(",", ""));
      formData.append("price3", String(price3).replaceAll(",", ""));
      formData.append("costFob", String(costFob).replaceAll(",", ""));
      formData.append("medCostFob", String(medCostFob).replaceAll(",", ""));
      formData.append("costCif", String(costCif).replaceAll(",", ""));
      formData.append("medCostCif", String(medCostCif).replaceAll(",", ""));
      formData.append("companyId", companyId);
      formData.append("itemTypeId", itemTypeId);
      formData.append("brandId", brandId);
      formData.append("groupId", groupId);
      formData.append("promotion", promotion);
      formData.append("weight", weight);
      formData.append("height", height);
      formData.append("broad", broad);
      formData.append("depth", depth);
      formData.append("website", website);
      formData.append("NCM", NCM);
      formData.append("measurementUnityId", measurementUnityId);
      formData.append("currencyId", currencyId);
      formData.append("observations", observations);
      formData.append("active", active);
      formData.append("deleted", deleted);

      let createdItemRequest = await axiosAuth.post("/items", formData);

      if (createdItemRequest.data.error) {
        if (createdItemRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newItems = [...items];
      newItems.unshift(createdItemRequest.data);
      setItems(newItems);
    }
    if (user.companyId === 2) {
      setOpen(false);
    } else {
      setOpen2(false);
    }

  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Desea eliminar este articulo ?")) {
      let itemDeleteRequest = await axiosAuth.delete("/items", {
        params: { id: itemId },
      });

      if (itemDeleteRequest.data.error) {
        if (itemDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newItemsObj = [];

      items.map((item) => {
        if (item.id !== itemId) {
          newItemsObj.push(item);
        } else {
          if (user.roleId == 1) {
            item.deleted = 1;
            newItemsObj.push(item);
          }
        }
        return "";
      });

      setItems(newItemsObj);
    }
  };

  const handleImageInputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImageFile(e.target.files[0]);
    setImage(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImagePreview(file);
  };

  const handleImage2InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage2File(e.target.files[0]);
    setImage2(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage2Preview(file);
  };

  const handleImage3InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage3File(e.target.files[0]);
    setImage3(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage3Preview(file);
  };

  const handleImage4InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage4File(e.target.files[0]);
    setImage4(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage4Preview(file);
  };

  const handleImage5InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage5File(e.target.files[0]);
    setImage5(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage5Preview(file);
  };

  const searchItems = async (searchTxt) => {
    setSearchText((prev) => searchTxt);
    let boole = "modal" ? 1 : 0;
    const searchItemsRequest = await axiosAuth.get(
      "/searchItems?search=" + searchText + "&boole=" + boole
    );
    //+"&boole=0"
    setItems(searchItemsRequest.data.items.rows);
    setPages(searchItemsRequest.data.items.pages);
    setTotal(searchItemsRequest.data.items.count);
  };
  const searchTypeItem = async (searchTxt) => {
    // Filtra los itemTypes basados en searchTxt
    const filteredItemTypes = itemTypes.filter(item =>
      item.name.toLowerCase().includes(searchTxt.toLowerCase()) // Ajusta 'name' según la estructura de tus objetos
    );

    // Actualiza el estado con los itemTypes filtrados
    setSearchItemTypes(filteredItemTypes);
  };
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  const handleBrandChange = (e) => {
    const value = e.target.value;
    if (value === 'add') {

      setShowNewBrandInput(true);
    } else {
      setShowNewBrandInput(false);
      setBrandId(value);
    }
  };

  const handleNewBrandKeyDown = async (e) => {
    if (e.key === 'Enter') {

      const response = await axiosAuth.post('/brands', { name: newBrand, companyId: companyId, deleted: 0 });

      brands.push(response.data);
      setBrands(brands);
      setNewBrand('')
      setShowNewBrandInput(false);
      setBrandId(response.data.id);
    }
  };
  const handleItemTypeChange = (e) => {
    const value = e.target.value;
    if (value === 'add') {
      setShowNewItemTypeInput(true);
    } else {
      setShowNewItemTypeInput(false);
      setItemTypeId(value);
    }
  };

  const handleNewItemTypeKeyDown = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await axiosAuth.post('/ItemTypes', { name: newItemType, companyId: companyId, deleted: 0 });
        const newItemTypeData = response.data;
        setItemTypes([...itemTypes, newItemTypeData]); // Crear un nuevo array para actualizar el estado
        setNewItemType(''); // Limpiar el campo de entrada
        setShowNewItemTypeInput(false); // Ocultar el campo de entrada
        setItemTypeId(newItemTypeData.id); // Establecer el nuevo tipo de artículo
      } catch (error) {
        console.error('Error creating item type:', error);
      }
    }
  };
  const handleGroupChange = (e) => {
    const value = e.target.value;
    if (value === 'add') {
      setShowNewGroupInput(true);
    } else {
      setShowNewGroupInput(false);
      setGroupId(value);
    }
  };

  const handleNewGroupKeyDown = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await axiosAuth.post('/groups', { name: newGroup, companyId: companyId, deleted: 0 });
        const newGroupData = response.data;
        setGroups([...groups, newGroupData]); // Actualizar la lista de grupos
        setNewGroup(''); // Limpiar el campo de entrada
        setShowNewGroupInput(false); // Ocultar el campo de entrada
        setGroupId(newGroupData.id); // Establecer el nuevo grupo
      } catch (error) {
        console.error('Error creating group:', error);
      }
    }
  };
  const handleMeasurementUnityChange = (e) => {
    const value = e.target.value;
    if (value === 'add') {
      setShowNewMeasurementUnityInput(true);
    } else {
      setShowNewMeasurementUnityInput(false);
      setMeasurementUnityId(value);
    }
  };

  const handleNewMeasurementUnityKeyDown = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await axiosAuth.post('/measurementUnits', { name: newMeasurementUnity, companyId: companyId, deleted: 0 });
        const newMeasurementUnityData = response.data;
        setMeasurementUnities([...measurementUnities, newMeasurementUnityData]); // Actualizar la lista de unidades de medida
        setNewMeasurementUnity(''); // Limpiar el campo de entrada
        setShowNewMeasurementUnityInput(false); // Ocultar el campo de entrada
        setMeasurementUnityId(newMeasurementUnityData.id); // Establecer la nueva unidad de medida
      } catch (error) {
        console.error('Error creating measurement unity:', error);
      }
    }
  };
  return (
    <>
      <Layout>
        <Transition.Root show={openImageModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpenImageModal(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                    style={{}}
                  >
                    <img alt="" className="w-full m-auto" src={imgPreview} />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={openStockModal} as={Fragment}>
  <Dialog
    as="div"
    className="relative z-10"
    onClose={() => {
      setOpenStockModal(false);
      setSelectedItem(null);
    }}
  >
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>

    <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto">
      <div className="flex justify-center p-4 text-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-12 sm:w-full sm:max-w-3xl">
            <div className="bg-white px-6 py-6">
              {/* Título centrado y estilizado */}
              <Dialog.Title
                as="h3"
                className="text-2xl font-bold leading-6 text-gray-900 text-center mb-6"
              >
                {selectedItem?.name || "Item Details"}
              </Dialog.Title>

              {/* Tabla */}
              <table className="w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border p-3 text-left text-sm font-medium">Deposito</th>
                    <th className="border p-3 text-right text-sm font-medium">Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItem?.stocks?.map((stock) => (
                    <tr key={stock.depositId}>
                      <td className="border p-3 text-left">{getDepositName(stock.depositId)}</td>
                      <td className="border p-3 text-right">{stock.existence}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-bold bg-gray-100">
                    <td className="border p-3">Total Stock</td>
                    <td className="border p-3 text-right">{calculateTotalStock()}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* Botón para cerrar */}
            <div className="bg-gray-50 px-6 py-4 flex justify-end">
              <button
                type="button"
                className="rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white hover:bg-blue-500"
                onClick={() => setOpenStockModal(false)}
              >
                Cerrar
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>



        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-[100%] m-auto overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-auto m-auto bg-white text-left shadow-xl transition-all w-[95%] sm:w-[85%] h-[550px]"
                    style={{}}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl mb-2"
                          >
                            {action === "edit" ? "Editar" : "Crear"} articulo
                          </Dialog.Title>
                          <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px] w-[80%]">
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    selected ? "bg-white font-bold" : ""
                                  )
                                }
                              >
                                Datos
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    selected ? "bg-white font-bold" : ""
                                  )
                                }
                              >
                                Imagen
                              </Tab>
                              {user.companyId == 2 && <>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected ? "bg-white font-bold" : ""
                                    )
                                  }
                                >
                                  Especificacion Web
                                </Tab>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected ? "bg-white font-bold" : ""
                                    )
                                  }
                                >
                                  Descripcion Web
                                </Tab>
                              </>
                              }
                            </Tab.List>
                            <Tab.Panels>
                              <Tab.Panel>
                                <div className="w-full flex flex-col md:flex-row w-full mt-2">
                                  <div className="mb-[1px] flex flex-col md:w-[50%] w-full">
                                    {action === "edit" ? (
                                      <div className="mb-1">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold"
                                          htmlFor="itemId"
                                        >
                                          ID:
                                        </label>
                                        &nbsp;
                                        <input
                                          readOnly
                                          className="inline-block input-bg-color appearance-none border rounded w-[91.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[23px]"
                                          id="itemId"
                                          type="text"
                                          value={itemId}
                                        />
                                      </div>
                                    ) : (<><div className="h-[23px]"></div></>)}

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="name"
                                      >
                                        (*) Nombre:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="description"
                                      >
                                        Descripcion:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="description"
                                        type="text"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="fiscalDescription"
                                      >
                                        Descripcion Fiscal:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="fiscalDescription"
                                        type="text"
                                        value={fiscalDescription}
                                        onChange={(e) =>
                                          setFiscalDescription(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="web"
                                      >
                                        Descripcion Web:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="web"
                                        type="text"
                                        value={web}
                                        onChange={(e) =>
                                          setWeb(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="barcode"
                                      >
                                        Cod de barras:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[72.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px]"
                                        id="barcode"
                                        type="text"
                                        value={barcode}
                                        onChange={(e) =>
                                          setBarcode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase "
                                        htmlFor="internalcode"
                                      >
                                        Cod interno:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color inline-block appearance-none border w-[73%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="internalcode"
                                        type="text"
                                        value={internalcode}
                                        onChange={(e) =>
                                          setInternalCode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] pb-2 mb-[5px]" style={{ borderBottom: "1px solid lightgray" }}>
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="factorycode"
                                      >
                                        Cod. de fabrica:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[71.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="factorycode"
                                        type="text"
                                        value={factorycode}
                                        onChange={(e) =>
                                          setFactoryCode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <h2 className="underline text-center text-2xl relative">Clasificacion</h2>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="itemTypeId"
                                      >
                                        (*) Tipo de articulo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[71%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="itemTypeId"
                                        value={itemTypeId}
                                        onChange={handleItemTypeChange}
                                      >
                                        <option value={0}>Elija un tipo de articulo</option>
                                        {itemTypes.map((itemType) => (
                                          <option key={itemType.id} value={itemType.id}>
                                            {itemType.name}
                                          </option>
                                        ))}
                                        <option value="add">Añadir nuevo tipo de artículo</option>
                                      </select>

                                      {showNewItemTypeInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[82%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newItemType}
                                          onChange={(e) => setNewItemType(e.target.value)}
                                          onKeyDown={handleNewItemTypeKeyDown}
                                        />
                                      )}
                                    </div>




                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="brandId"
                                      >
                                        (*) Marca:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[82%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="brandId"
                                        value={brandId}
                                        onChange={handleBrandChange}
                                      >
                                        <option value={0}>
                                          Elija una marca
                                        </option>
                                        {brands.map((brand) => (
                                          <option
                                            key={brand.id}
                                            value={brand.id}
                                          >
                                            {brand.name}
                                          </option>
                                        ))}
                                      </select>
                                      {showNewBrandInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[82%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newBrand}
                                          onChange={(e) => setNewBrand(e.target.value)}
                                          onKeyDown={handleNewBrandKeyDown}
                                        />
                                      )}
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="groupId"
                                      >
                                        (*) Grupo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[82.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="groupId"
                                        value={groupId}
                                        onChange={handleGroupChange}
                                      >
                                        <option value={0}>Elija un grupo</option>
                                        {groups.map((group) => (
                                          <option key={group.id} value={group.id}>
                                            {group.name}
                                          </option>
                                        ))}
                                        <option value="add">Añadir nuevo grupo</option>
                                      </select>

                                      {showNewGroupInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[82.5%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newGroup}
                                          onChange={(e) => setNewGroup(e.target.value)}
                                          onKeyDown={handleNewGroupKeyDown}
                                        />
                                      )}
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="promotion"
                                      >
                                        Promocion:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[82.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="promotion"
                                        value={promotion}
                                        onChange={(e) => {
                                          setPromotion(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un valor
                                        </option>
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="website"
                                      >
                                        Website:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[82.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="website"
                                        value={website}
                                        onChange={(e) => {
                                          setWebsite(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un valor
                                        </option>
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="ncm"
                                      >
                                        NCM:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[72.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="ncm"
                                        type="text"
                                        value={NCM}
                                        onChange={(e) =>
                                          setNCM(e.target.value)
                                        }
                                      />
                                    </div>


                                    <div className="flex flex-row justify-start items-center">
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="weight"
                                        >
                                          Peso:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="weight"
                                          type="text"
                                          value={weight}
                                          onChange={(e) =>
                                            setWeight(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="height"
                                        >
                                          Alto:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="height"
                                          type="text"
                                          value={height}
                                          onChange={(e) =>
                                            setHeight(e.target.value)
                                          }
                                        />
                                      </div>


                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="broad"
                                        >
                                          Largo:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="broad"
                                          type="text"
                                          value={broad}
                                          onChange={(e) =>
                                            setBroad(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="depth"
                                        >
                                          Prof.:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="depth"
                                          type="text"
                                          value={depth}
                                          onChange={(e) =>
                                            setDepth(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>


                                  </div>

                                  <div className="mb-[1px] flex flex-col md:w-[50%] w-full">


                                    <div className="flex sm:flex-row flex-col pb-[3px] mt-[5px]" style={{ borderBottom: "1px solid lightgray" }}>
                                      <div className="flex flex-col">
                                        <h2 className="text-center text-2xl underline text-green-600">Precios / Impuesto</h2>
                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                            htmlFor="price"
                                          >
                                            (*) Precio Tienda:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[60%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="price"
                                            type="text"
                                            value={price}
                                            onChange={(e) =>
                                              setPrice(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="price2"
                                          >
                                            Precio Web:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                            id="price2"
                                            type="text"
                                            value={price2}
                                            onChange={(e) =>
                                              setPrice2(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="price3"
                                          >
                                            Precio Minimo:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="price3"
                                            type="text"
                                            value={price3}
                                            onChange={(e) =>
                                              setPrice3(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="iva"
                                          >
                                            IVA:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] w-[65%] input-bg-color appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="iva"
                                            type="text"
                                            value={iva}
                                            onChange={(e) => setIva(e.target.value)}
                                          />
                                        </div>
                                      </div>

                                      <div className="flex flex-col">

                                        <h2 className="text-center text-2xl underline text-red-400">Costos</h2>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costFob"
                                          >
                                            Costo Fob.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costFob"
                                            type="text"
                                            value={costFob}
                                            onChange={(e) =>
                                              setCostFob(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costFob"
                                          >
                                            Med. Costo Fob.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costFob"
                                            type="text"
                                            value={medCostFob}
                                            onChange={(e) =>
                                              setMedCostFob(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costCif"
                                          >
                                            Costo Cif.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costCif"
                                            type="text"
                                            value={costCif}
                                            onChange={(e) =>
                                              setCostCif(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costCif"
                                          >
                                            Med. Costo Cif.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costCif"
                                            type="text"
                                            value={medCostCif}
                                            onChange={(e) =>
                                              setMedCostCif(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                    </div>

                                    <div className="sm:mt-11 mt-2"></div>

                                    {user.roleId == 1 &&
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="companyId"
                                        >
                                          Empresa
                                        </label>
                                        <select
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[85%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                          id="companyId"
                                          value={companyId}
                                          onChange={(e) => {
                                            setCompanyId(e.target.value);
                                          }}
                                        >
                                          <option value={0}>
                                            Elija una empresa
                                          </option>
                                          {companies.map((company) => (
                                            <option
                                              key={company.id}
                                              value={company.id}
                                            >
                                              {company.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    }


                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="measurementUnityId"
                                      >
                                        (*) Unidad de medida:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[70.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="measurementUnityId"
                                        value={measurementUnityId}
                                        onChange={handleMeasurementUnityChange}
                                      >
                                        <option value={0}>Elija una un. de medida</option>
                                        {measurementUnities.map((measurementUnity) => (
                                          <option key={measurementUnity.id} value={measurementUnity.id}>
                                            {measurementUnity.name}
                                          </option>
                                        ))}
                                        <option value="add">Añadir nueva un. de medida</option>
                                      </select>

                                      {showNewMeasurementUnityInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[70.5%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newMeasurementUnity}
                                          onChange={(e) => setNewMeasurementUnity(e.target.value)}
                                          onKeyDown={handleNewMeasurementUnityKeyDown}
                                        />
                                      )}
                                    </div>


                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="currencyId"
                                      >
                                        (*) Moneda:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color h-[23px] appearance-none border w-[85.2%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="currencyId"
                                        value={currencyId}
                                        onChange={(e) => {
                                          setCurrencyId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una moneda
                                        </option>
                                        {currencies.map((currency) => (
                                          <option
                                            key={currency.id}
                                            value={currency.id}
                                          >
                                            {currency.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {user.companyId != 2 && <>



                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="observations"
                                        >
                                          Observaciones:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[74%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                          id="observations"
                                          type="text"
                                          value={observations}
                                          onChange={(e) =>
                                            setObservations(e.target.value)
                                          }
                                        />
                                      </div>
                                    </>
                                    }

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="active"
                                      >
                                        Activa:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[88%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="active"
                                        value={active}
                                        onChange={(e) =>
                                          setActive(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    {user.roleId == 1 && (
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block primary-color text-sm font-bold"
                                          htmlFor="deleted"
                                        >
                                          BORRADO:
                                        </label>
                                        &nbsp;
                                        <select
                                          className="inline-block h-[23px] input-bg-color appearance-none border rounded w-[84%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                          id="deleted"
                                          value={deleted}
                                          onChange={(e) =>
                                            setDeleted(parseInt(e.target.value))
                                          }
                                        >
                                          <option value={0}>No</option>
                                          <option value={1}>Si</option>
                                        </select>
                                      </div>
                                    )}

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="lastSell"
                                      >
                                        Ultima venta:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[80%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="lastSell"
                                        type="date"
                                        value={lastSell}
                                        readOnly
                                        disabled
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="lastBuy"
                                      >
                                        Ultima compra:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[80%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="lastBuy"
                                        type="date"
                                        value={lastBuy}
                                        readOnly
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Panel>
                              <Tab.Panel>
                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block w-[50%] primary-color mr-1 text-sm font-bold"
                                    htmlFor="image"
                                  >
                                    Foto 1
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={{ backgroundImage: "url('" + imagePreview + "')" }}
                                    >
                                      {imagePreview === "" ? "+" : ""}
                                      {imagePreview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700 p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage("");
                                            setImagePreview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>

                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image2"
                                  >
                                    Foto 2
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image2Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image2Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image2Preview === "" ? "+" : ""}
                                      {image2Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage2("");
                                            setImage2Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>

                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image3"
                                  >
                                    Foto 3
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image3Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image3Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image3Preview === "" ? "+" : ""}
                                      {image3Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage3("");
                                            setImage3Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>

                                  <label
                                    className="block w-[50%] primary-color ml-1 text-sm font-bold"
                                    htmlFor="image4"
                                  >
                                    Foto 4
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image4Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image4Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image4Preview === "" ? "+" : ""}
                                      {image4Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage4("");
                                            setImage4Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>
                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image5"
                                  >
                                    Foto 5
                                    <br />
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image5Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image5Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image5Preview === "" ? "+" : ""}
                                      {image5Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage5("");
                                            setImage5Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image"
                                  type="file"
                                  onChange={(e) => handleImageInputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image2"
                                  type="file"
                                  onChange={(e) => handleImage2InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image3"
                                  type="file"
                                  onChange={(e) => handleImage3InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image4"
                                  type="file"
                                  onChange={(e) => handleImage4InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image5"
                                  type="file"
                                  onChange={(e) => handleImage5InputChange(e)}
                                //value={image}
                                />
                              </Tab.Panel>
                              <Tab.Panel>
                                <textarea className="w-full h-80" value={webSpecification} onChange={e => setWebSpecification(e.target.value)}>
                                </textarea>
                              </Tab.Panel>
                              <Tab.Panel>
                                <textarea className="w-full h-80" value={webDescription} onChange={e => setWebDescription(e.target.value)}>
                                </textarea>
                              </Tab.Panel>
                            </Tab.Panels>
                          </Tab.Group>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">

                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img alt="" src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img alt="" src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={open2} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 sm:w-[100%] max-w-[750px] m-auto overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-auto m-auto bg-white text-left shadow-xl transition-all w-[95%] sm:full h-[550px]"

                    style={{}}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl mb-2"
                          >
                            {action === "edit" ? "Editar" : "Crear"} articulo
                          </Dialog.Title>
                          <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px] w-[100%] ">
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-[45%] rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    selected ? "bg-white font-bold" : ""
                                  )
                                }
                              >
                                Datos
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-[45%] rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    selected ? "bg-white font-bold" : ""
                                  )
                                }
                              >
                                Imagen
                              </Tab>
                              {user.companyId == 2 && <>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected ? "bg-white font-bold" : ""
                                    )
                                  }
                                >
                                  Especificacion Web
                                </Tab>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected ? "bg-white font-bold" : ""
                                    )
                                  }
                                >
                                  Descripcion Web
                                </Tab>
                              </>
                              }
                            </Tab.List>
                            <Tab.Panels>
                              <Tab.Panel>
                                <div className="w-full flex flex-col md:flex-row w-full mt-2">
                                  <div className="mb-[1px] flex flex-col md:w-[60%] w-full">
                                    {action === "edit" ? (
                                      <div className="mb-1">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold"
                                          htmlFor="itemId"
                                        >
                                          ID:
                                        </label>
                                        &nbsp;
                                        <input
                                          readOnly
                                          className="inline-block input-bg-color appearance-none border rounded w-[91.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[23px]"
                                          id="itemId"
                                          type="text"
                                          value={itemId}
                                        />
                                      </div>
                                    ) : (<><div className="h-[23px]"></div></>)}

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="name"
                                      >
                                        (*) Nombre:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[79%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="barcode"
                                      >
                                        Cod de barras:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[73%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px]"
                                        id="barcode"
                                        type="text"
                                        value={barcode}
                                        onChange={(e) =>
                                          setBarcode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase "
                                        htmlFor="internalcode"
                                      >
                                        Cod interno:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color inline-block appearance-none border w-[75%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="internalcode"
                                        type="text"
                                        value={internalcode}
                                        onChange={(e) =>
                                          setInternalCode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] pb-2 mb-[5px]" style={{ borderBottom: "1px solid lightgray" }}>
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="factorycode"
                                      >
                                        Cod. de fabrica:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[72%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="factorycode"
                                        type="text"
                                        value={factorycode}
                                        onChange={(e) =>
                                          setFactoryCode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <h2 className="underline text-center text-2xl relative">Clasificacion</h2>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="itemTypeId"
                                      >
                                        (*) Tipo de articulo:
                                      </label>
                                      &nbsp;
                                      {/* <select
                                        className="inline-block input-bg-color appearance-none border w-[65%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="itemTypeId"
                                        value={itemTypeId}
                                        onChange={handleItemTypeChange}
                                      >
                                        <option value={0}>Elija un tipo de articulo</option>
                                        {itemTypes.map((itemType) => (
                                          <option key={itemType.id} value={itemType.id}>
                                            {itemType.name}
                                          </option>
                                        ))}
                                        +
                                      </select> */}{/* aca nuevo c */}
                                      <ComboBox
                                        data={searchItemTypes}
                                        setData={setItemTypeId}
                                        value={itemTypeId}
                                        placeholder="Tipo de articulo"
                                        search={searchTypeItem}
                                        className="h-[28.1px] border-t-1"
                                      />

                                      {showNewItemTypeInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[82%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newItemType}
                                          onChange={(e) => setNewItemType(e.target.value)}
                                          onKeyDown={handleNewItemTypeKeyDown}
                                        />
                                      )}
                                    </div>

                                    <div className="mb-[1px]" >
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="brandId"
                                      >
                                        (*) Marca:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[80%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="brandId"
                                        value={brandId}
                                        onChange={handleBrandChange}
                                      >
                                        <option value={0}>
                                          Elija una marca
                                        </option>
                                        {brands.map((brand) => (
                                          <option
                                            key={brand.id}
                                            value={brand.id}
                                          >
                                            {brand.name}
                                          </option>
                                        ))}
                                      </select>
                                      {showNewBrandInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[82%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newBrand}
                                          onChange={(e) => setNewBrand(e.target.value)}
                                          onKeyDown={handleNewBrandKeyDown}
                                        />
                                      )}
                                    </div>

                                    <div className="mb-[1px]" style={{ borderBottom: "1px solid lightgray", paddingBottom: "5px" }}>
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="groupId"
                                      >
                                        (*) Grupo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="groupId"
                                        value={groupId}
                                        onChange={handleGroupChange}
                                      >
                                        <option value={0}>Elija un grupo</option>
                                        {groups.map((group) => (
                                          <option key={group.id} value={group.id}>
                                            {group.name}
                                          </option>
                                        ))}
                                        <option value="add">Añadir nuevo grupo</option>
                                      </select>

                                      {showNewGroupInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[82.5%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newGroup}
                                          onChange={(e) => setNewGroup(e.target.value)}
                                          onKeyDown={handleNewGroupKeyDown}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="mb-[1px] flex flex-col md:w-[50%] w-full">
                                    <div className="mb-[1px]">
                                      <h2 className="underline text-center text-2xl relative">Precios / Impuesto</h2>
                                    </div>
                                    <div className="flex sm:flex-row flex-col pb-[3px] mt-[5px]">
                                      <div className="flex flex-col">

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                            htmlFor="price"
                                          >
                                            (*) Precio 1:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[60%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="price"
                                            type="text"
                                            value={price}
                                            onChange={(e) =>
                                              setPrice(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="price2"
                                          >
                                            Precio 2:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                            id="price2"
                                            type="text"
                                            value={price2}
                                            onChange={(e) =>
                                              setPrice2(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="price3"
                                          >
                                            Precio 3:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="price3"
                                            type="text"
                                            value={price3}
                                            onChange={(e) =>
                                              setPrice3(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costFob"
                                          >
                                            Ultimo Costo:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costFob"
                                            type="text"
                                            value={costFob}
                                            onChange={(e) =>
                                              setCostFob(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="iva"
                                          >
                                            IVA:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] w-[65%] input-bg-color appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="iva"
                                            type="text"
                                            value={iva}
                                            onChange={(e) => setIva(e.target.value)}
                                          />
                                        </div>
                                      </div>

                                    </div>





                                    <div className="sm:mt-11 mt-2"></div>

                                    {user.roleId == 1 &&
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="companyId"
                                        >
                                          Empresa
                                        </label>
                                        <select
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[73.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                          id="companyId"
                                          value={companyId}
                                          onChange={(e) => {
                                            setCompanyId(e.target.value);
                                          }}
                                        >
                                          <option value={0}>
                                            Elija una empresa
                                          </option>
                                          {companies.map((company) => (
                                            <option
                                              key={company.id}
                                              value={company.id}
                                            >
                                              {company.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    }


                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="measurementUnityId"
                                      >
                                        (*) Unidad de medida:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="measurementUnityId"
                                        value={measurementUnityId}
                                        onChange={handleMeasurementUnityChange}
                                      >
                                        <option value={0}>Elija una un. de medida</option>
                                        {measurementUnities.map((measurementUnity) => (
                                          <option key={measurementUnity.id} value={measurementUnity.id}>
                                            {measurementUnity.name}
                                          </option>
                                        ))}
                                        <option value="add">Añadir nueva un. de medida</option>
                                      </select>

                                      {showNewMeasurementUnityInput && (
                                        <input
                                          type="text"
                                          className="inline-block input-bg-color appearance-none border w-[70.5%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase mt-2"
                                          placeholder="Ingrese nombre y guardar con Enter"
                                          value={newMeasurementUnity}
                                          onChange={(e) => setNewMeasurementUnity(e.target.value)}
                                          onKeyDown={handleNewMeasurementUnityKeyDown}
                                        />
                                      )}
                                    </div>


                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="currencyId"
                                      >
                                        (*) Moneda:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color h-[23px] appearance-none border w-[71%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="currencyId"
                                        value={currencyId}
                                        onChange={(e) => {
                                          setCurrencyId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una moneda
                                        </option>
                                        {currencies.map((currency) => (
                                          <option
                                            key={currency.id}
                                            value={currency.id}
                                          >
                                            {currency.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {user.companyId != 2 && <>

                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="observations"
                                        >
                                          Observaciones:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                          id="observations"
                                          type="text"
                                          value={observations}
                                          onChange={(e) =>
                                            setObservations(e.target.value)
                                          }
                                        />
                                      </div>
                                    </>
                                    }

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="active"
                                      >
                                        Activa:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[70%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="active"
                                        value={active}
                                        onChange={(e) =>
                                          setActive(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    {user.roleId == 1 && (
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block primary-color text-sm font-bold"
                                          htmlFor="deleted"
                                        >
                                          BORRADO:
                                        </label>
                                        &nbsp;
                                        <select
                                          className="inline-block h-[23px] input-bg-color appearance-none border rounded w-[72%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                          id="deleted"
                                          value={deleted}
                                          onChange={(e) =>
                                            setDeleted(parseInt(e.target.value))
                                          }
                                        >
                                          <option value={0}>No</option>
                                          <option value={1}>Si</option>
                                        </select>
                                      </div>
                                    )}

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="lastSell"
                                      >
                                        Ultima venta:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[68.5%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="lastSell"
                                        type="date"
                                        value={lastSell}
                                        readOnly
                                        disabled
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="lastBuy"
                                      >
                                        Ultima compra:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[64%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="lastBuy"
                                        type="date"
                                        value={lastBuy}
                                        readOnly
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Panel>
                              <Tab.Panel>
                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block w-[50%] primary-color mr-1 text-sm font-bold"
                                    htmlFor="image"
                                  >
                                    Foto 1
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={{ backgroundImage: "url('" + imagePreview + "')" }}
                                    >
                                      {imagePreview === "" ? "+" : ""}
                                      {imagePreview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700 p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage("");
                                            setImagePreview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>

                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image2"
                                  >
                                    Foto 2
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image2Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image2Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image2Preview === "" ? "+" : ""}
                                      {image2Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage2("");
                                            setImage2Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>

                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image3"
                                  >
                                    Foto 3
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image3Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image3Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image3Preview === "" ? "+" : ""}
                                      {image3Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage3("");
                                            setImage3Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>

                                  <label
                                    className="block w-[50%] primary-color ml-1 text-sm font-bold"
                                    htmlFor="image4"
                                  >
                                    Foto 4
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image4Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image4Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image4Preview === "" ? "+" : ""}
                                      {image4Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage4("");
                                            setImage4Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>
                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image5"
                                  >
                                    Foto 5
                                    <br />
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image5Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image5Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image5Preview === "" ? "+" : ""}
                                      {image5Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage5("");
                                            setImage5Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image"
                                  type="file"
                                  onChange={(e) => handleImageInputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image2"
                                  type="file"
                                  onChange={(e) => handleImage2InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image3"
                                  type="file"
                                  onChange={(e) => handleImage3InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image4"
                                  type="file"
                                  onChange={(e) => handleImage4InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image5"
                                  type="file"
                                  onChange={(e) => handleImage5InputChange(e)}
                                //value={image}
                                />
                              </Tab.Panel>
                              <Tab.Panel>
                                <textarea className="w-full h-80" value={webSpecification} onChange={e => setWebSpecification(e.target.value)}>
                                </textarea>
                              </Tab.Panel>
                              <Tab.Panel>
                                <textarea className="w-full h-80" value={webDescription} onChange={e => setWebDescription(e.target.value)}>
                                </textarea>
                              </Tab.Panel>
                            </Tab.Panels>
                          </Tab.Group>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">

                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img alt="" src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields2();
                          setOpen2(false);
                        }}
                      >
                        <img alt="" src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          class="w-full top-[-40px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-7 secondary-color title font-family-thin">
              Articulos
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>

          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] sm:mt-5">
              Buscar:
            </label>
            <input
              value={searchText}
              className="input-bg-color appearance-none border w-[350px]  rounded  
            py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[35px] sm:mt-5"
              placeholder="Nombre de Articulo,Cod.de Barras"
              onChange={(e) => searchItems(e.target.value)}
            />
            <motion.button
              initial={false}
              whileTap="press"
              onClick={() => {
                if (user.companyId === 2) {
                  setAction("create");
                  setOpen(true);
                } else {
                  setAction("create");
                  setOpen2(true);
                }
              }}
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px] "
            >
              {/*<Suspense fallback={null}>
                <BoxesIcon isHover={true} />
            </Suspense>*/}
              <img alt="" src={plus} className="w-8" />
              AGREGAR
            </motion.button>
          </div>
        </div>

        {/* <button
          className="rounded-md px-2 py-1 float-right relative right-10 bottom-10 cursor-pointer title-button mt-20"
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          Crear articulo
        </button> */}

        {/* <button
          type="button"
          className="inline-flex flex-col float-right w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 relative right-[23px] sm:right-[50px] bottom-[15px]"
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={product} className="w-6" />
          CREAR ARTICULO
        </button> */}

        <div className="w-[95%] p-6 mx-auto overflow-auto sm:max-h-[70vh] max-h-[70vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead>
              {width > 1024 ? (
                <tr className="bg-gray-500 h-8">
                  <th className="uppercase !text-white">Id</th>
                  <th className="uppercase w-80 !text-white text-left">Nombre</th>
                  <th className="uppercase !text-white text-left">Cod. de Barras</th>
                  <th className="uppercase !text-white text-right pr-[20px]">Precio</th>
                  {user.roleId == 1 && (
                    <th className="uppercase !text-white text-left">Empresa</th>
                  )}
                  <th className="uppercase  !text-white text-left pr-[20px]">Tipo</th>
                  <th className="uppercase  !text-white text-left">Marca</th>
                  <th className="uppercase  !text-white text-left">Grupo</th>
                  <th className="uppercase  !text-white">Activa</th>
                  {user.roleId == 1 && (
                    <th className="uppercase  !text-white">Borrado</th>
                  )}
                  <th className="uppercase  !text-white">Accion</th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {items.length > 0 &&
                !isFetching &&
                items?.map((item) => {
                  if (width > 1024) {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td className="text-left whitespace-nowrap">
                          {item.name}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {item.barcode}
                        </td>
                        {/* <td>{item.internalcode}</td> */}
                        {/* <td>{item.factorycode}</td> */}
                        <td className="text-right whitespace-nowrap pr-[20px]">
                          {" "}
                          {numberWithCommas(item.price)}
                        </td>
                        {/* <td>{numberWithCommas(item.price2)}</td>
                        <td>{numberWithCommas(item.price3)}</td> */}
                        {/*<td>
                         <img
                        alt=""
                        style={{ width: 40 }}
                        className="cursor-pointer"
                        src={item.image
                          ? endpoint + "itemImages/" + item.image
                          : endpoint + "logo-gray.png"}
                        onClick={() => {
                          setImgPreview(
                            item.image
                              ? endpoint + "itemImages/" + item.image
                              : endpoint + "logo-gray.png"
                          );
                          setOpenImageModal(true);
                        } } />
                      </td>*/}
                        {user.roleId == 1 && (
                          <td className="text-left whitespace-nowrap ">
                            {item.company.name}
                          </td>
                        )}
                        <td className="text-left">{item?.itemtype?.name}</td>
                        <td className="text-left whitespace-nowrap">
                          {item?.brand?.name}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {item?.group?.name}
                        </td>
                        {/* <td>{item?.measurementunity?.name}</td> */}
                        {/* <td>{item?.currency?.name}</td> */}
                        {/*<td>{item.observations}</td> */}
                        <td>{item.active ? "Si" : "No"}</td>
                        {user.roleId == 1 && (
                          <td>{item.deleted ? "Si" : "No"}</td>
                        )}
                        {/* <td>
                      {item?.createdAt
                        ?.substr(0, 10)
                        .split("-")
                        .reverse()
                        .join("/")}
                    </td> */}
                        <td className="text-center">
                          <button
                            className="m-[1px] bg-transparent"
                            onClick={() => {
                              viewStock(item.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="cursor-pointer secondary-color"
                              icon={faEye}
                            />
                          </button>
                          <button
                            className="m-[1px] bg-transparent"
                            onClick={() => {
                              // console.log("aaaa");

                              if (user.companyId === 2) {
                                editItem(item.id);
                              } else {
                                editItem2(item.id);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              className="cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="m-[1px] bg-transparent"
                            onClick={() => {
                              deleteItem(item.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="cursor-pointer secondary-color"
                              icon={faTrash}
                            />

                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <label className="font-bold">ID: </label>
                        {item.id}
                        <br />
                        <label className="font-bold">DESCRIPCION: </label>
                        {item.name}
                        <br />
                        <label className="font-bold">COD. DE BARRAS: </label>
                        {item.barcode}
                        <br />
                        <label className="font-bold">PRECIO: </label>
                        {item.price}
                        <br />
                        <label className="font-bold">ACCION:</label>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            if (user.companyId === 2) {
                              editItem(item.id);
                            } else {
                              editItem2(item.id);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            deleteItem(item.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>

                      </tr>
                    );
                  }
                })}
              {items.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={21} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {
          <Pagination

            path="articulos"
            page={page}
            pages={pages}
            total={total}
            params={{ search }}
            style={{ borderTop: "1px solid lightgray", marginTop: "-15px" }}

          />

        }
      </Layout>
    </>
  );
};

export default Items;
