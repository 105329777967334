import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { endpoint, menuitems } from "../utils/constants";
import { axiosAuth } from "../utils/axios";
import { setToken, setUser } from "../store/slices/mainSlice";
import { easeInOut, motion } from "framer-motion";
import Lottie from "lottie-react";
import heartAnimation from "../assets/animations/heart.json";

import {
  faUserCircle,
  faPowerOff,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const Content = (props) => {
  const user = useSelector((state) => state.main.user);
  const dispatch = useDispatch();
  const location = useLocation();

  const [menu, setMenu] = useState([]); // menuitems

  useEffect(() => {
    let menuObj = [];
    user.access.map((menu) => {
      if (menu.submenuId == null) {
        menuObj.push({
          id: menu.menuId,
          path: menu.menu.path,
          icon: menu.menu.icon,
          name: menu.menu.name,
        });
      } else {
        let alreadyInObject = false;

        menuObj.map((menuOb) => {
          if (menuOb.id == menu.menuId) {
            alreadyInObject = true;
          }
        });

        if (!alreadyInObject) {
          menuObj.push({
            id: menu.menuId,
            icon: menu.menu.icon,
            name: menu.menu.name,
            submenu: {
              open: false,
              items: [
                {
                  path: menu.submenu.path,
                  icon: menu.submenu.icon,
                  name: menu.submenu.name,
                },
              ],
            },
          });
        } else {
          menuObj.map((menuOb) => {
            if (menuOb.id == menu.menuId) {
              if(menuOb.submenu == undefined){
                menuOb.submenu = { open: false, items: [] };
              }
              menuOb.submenu.open = false;
              menuOb.submenu.items.push({
                path: menu.submenu.path,
                icon: menu.submenu.icon,
                name: menu.submenu.name,
              });
            }
          });
        }
      }
    });

    menuObj.map((menuOb, i) => {
      if (Object.hasOwn(menuOb, "submenu")) {
        let setSubmenuOpen = false;

        menuOb.submenu.items.map((item) => {
          if (
            location.pathname != "/" &&
            location.pathname.indexOf(item.path) > -1
          ) {
            setSubmenuOpen = true;
          }
        });

        if (setSubmenuOpen) {
          menuOb.submenu.open = true;
        }

        // menuOb.submenu.items = menuOb.submenu.items.sort(function(a, b) {
        //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase

        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }

        //   // names must be equal
        //   return 0;
        // });
      }
    });

    menuObj = menuObj.sort(function (a, b) {

      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setMenu(menuObj);
  }, [user.access]);

  const subMenuVariants = {
    hidden: { display: "none", opacity: 0, height: 0 },
    show: {
      display: "block",
      opacity: 1,
      height: "auto",
      transition: { staggerChildren: 0.1 },
    },
  };

  const subMenuItemVariants = {
    hidden: { opacity: 0, x: 20 },
    show: { opacity: 1, x: 0 },
  };

  const chevronVariants = {
    normal: { rotate: 0, y: 5 },
    open: { rotate: 180, x: 10, y: -2 },
  };

  const makeLogout = async () => {
    await axiosAuth.post("/logout");
    dispatch(setUser(null));
    dispatch(setToken(null));
  };

  return (
    <motion.main
      initial={{ y: 50 }}
      animate={{ y: 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
      className="overflow-auto relative shadow-2xl"
    >
      <div className="user float-right mt-2 mr-2 flex fixed right-0 z-10">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className="inline-flex w-full justify-center items-center primary-color "
              style={{ backgroundColor: "none !important" }}
            >
              <p className="w-auto inline-block mr-2 !text-[15px] primary-color sm:block hidden">
                Hola {user.fullname.split(" ")[0]}!
              </p>
              {user.image != "" ? (
                <div
                  className="bg-red-200 w-9 h-9 rounded-full bg-center bg-cover bg-norepeat shadow-md shadow-black"
                  style={{
                    backgroundImage:
                      "url(" + endpoint + "userImages/" + user.image + ")",
                  }}
                ></div>
              ) : (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="text-3xl primary-color cursor-pointer"
                  onClick={() => {} /*setTooltipOpen(!tooltipOpen)*/}
                />
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="mobile-menu absolute right-0 mt-3 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pl-2 z-10 max-h-[350px] overflow-y-auto overflow-x-hidden text-[15px]">
              {menu.map((item, i) => (
                <>
                  {item.submenu == undefined ? (
                    <div key={i} className="px-2 py-1 md:hidden">
                      <NavLink
                        to={
                          item.path != "/" ? String("/" + item.path) : item.path
                        }
                      >
                        <div className="flex align-center mb-1">
                          <FontAwesomeIcon
                            icon={item.icon}
                            style={{ bottom: -5, position: "relative" }}
                            className="primary-color"
                          />
                          <p className="ml-2 relative bottom-[-3px] primary-color">
                            {item.name}
                          </p>
                        </div>
                      </NavLink>
                    </div>
                  ) : (
                    <>
                      <div
                        key={i}
                        className="flex align-center mb-2 pt-1 cursor-pointer ml-2 md:hidden"
                        onClick={() => {
                          let newMenu = [...menu];
                          newMenu.map((newMenuItem) => {
                            if(Object.hasOwn(newMenuItem, 'submenu')){
                              if (newMenuItem.name != item.name) {
                                newMenuItem.submenu.open = false;
                              }
                            }
                            if (newMenuItem.name == item.name) {
                              newMenuItem.submenu.open = !newMenuItem.submenu.open;
                            }
                          });
                          setMenu(newMenu);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          style={{ bottom: -5, position: "relative" }}
                          className="primary-color"
                        />
                        <p className="ml-2 relative bottom-[-3px]">
                          {item.name}
                        </p>
                        <motion.div
                          initial="normal"
                          animate={item.submenu.open ? "open" : "normal"}
                          variants={chevronVariants}
                          className="relative bottom-[-1px]"
                        >
                          <FontAwesomeIcon
                            className="menu-chevron ml-2 primary-color"
                            icon={faChevronDown}
                          />
                        </motion.div>
                      </div>

                      <motion.div
                        initial="hidden"
                        animate={item.submenu.open ? "show" : "hidden"}
                        variants={subMenuVariants}
                        className="ml-5"
                      >
                        {item.submenu.items.map((subItem, i) => (
                          <motion.div
                            key={i}
                            variants={subMenuItemVariants}
                            className="pt-[4px] pb-2 md:hidden"
                          >
                            <NavLink to={"/" + subItem.path} className="">
                              <div className="flex align-center">
                                <FontAwesomeIcon
                                  icon={subItem.icon}
                                  style={{
                                    bottom: -5,
                                    position: "relative",
                                  }}
                                  className="primary-color"
                                />
                                <p className="ml-2 relative bottom-[-2px] primary-color">
                                  {subItem.name}
                                </p>
                              </div>
                            </NavLink>
                          </motion.div>
                        ))}
                      </motion.div>
                    </>
                  )}
                </>
              ))}

              <div className="px-2 py-2">
                <Menu.Item>
                  <div
                    className="cursor-pointer primary-color"
                    onClick={() => {
                      makeLogout();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      style={{ marginRight: 8 }}
                      className="primary-color"
                    />
                    Cerrar sesion
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {props.children}
      <p
        className={`text-center flex flex-row justify-center items-center text-sm text-slate-500 w-[93%] ${
          location.pathname.indexOf("facturacion") > -1 ||
          location.pathname.indexOf("compras") > -1 ||
          location.pathname.indexOf("articulos") > -1 ||
          location.pathname.indexOf("ventas") > -1 ||
          location.pathname.indexOf("clientes") > -1 ||
          location.pathname.indexOf("cotizar") > -1 ||
          location.pathname.indexOf("seguimiento") > -1 ||
          location.pathname.indexOf("reporte") > -1 ||
          location.pathname.indexOf("contratos") > -1 ||
          location.pathname.indexOf("registro") > -1
            ? "pb-2 mb-2"
            : "fixed bottom-[0]"
        }`}
      >
        {/* <div className="flex flex-row justify-center items-center relative bottom-1">
          <span className="font-family-thin relative right-[-8px] z-10">
            Made with
          </span>
          <Lottie
            animationData={heartAnimation}
            style={{ width: 40, height: 40 }}
            loop={true}
          />
          <span className="relative left-[-7px] font-family-thin">
            by&nbsp;
          </span>
          <b className="relative left-[-7px]">
            <Link to={"https://www.ocus.com.py"} target="_blank">
              {" "}
              Ocus
            </Link>
          </b>
        </div> */}
      </p>

    
    </motion.main>
  );
};

export default Content;
