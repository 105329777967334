import React, { useEffect } from "react";  // Agregar useEffect aquí
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Accounts from "./pages/accounts";
import AccounTypes from "./pages/accountypes";
import Banks from "./pages/banks";
import BankDeposits from "./pages/bankdeposits";
import BankDeposiTypes from "./pages/bankdepositypes";
import BankStatement from "./pages/bankstatement";
import Boxes from "./pages/boxes";
import BoxOpenings from "./pages/boxopenings";
import Branches from "./pages/branches";
import Brands from "./pages/brands";
import Cities from "./pages/cities";
import CheckStatements from "./pages/checkstatements";
import Clients from "./pages/clients";
import ClientAccountState from "./pages/clientaccountstate";
import ClientAccountStatePrint from "./pages/clientaccountstateprint";
import CollectionPrint from "./pages/collectionprint";
import CollectionsReport from "./pages/collectionsreport";
import CollectionPDF from "./pages/collectionpdf";
import Collections from "./pages/collections";
import CollectionsViewer from "./pages/collectionsviewer";
import Companies from "./pages/companies";
import Contacts from "./pages/contacts";
import Contracts from "./pages/contracts";
import Currencies from "./pages/currencies";
import Dashboard from "./pages/dashboard";
import DataOrigins from "./pages/dataorigins";
import Deposits from "./pages/deposits";
import Groups from "./pages/groups";
import ReceivedChecks from "./pages/receivedchecks";
import Items from "./pages/items";
import ItemTypes from "./pages/itemtypes";
import Login from "./pages/login";
import MeasurementUnits from "./pages/measurementunits";
import Movements from "./pages/movements";
import MovemenTypes from "./pages/movementypes";
import SaleConditions from "./pages/saleconditions";
import Sales from "./pages/sales";
import SalesCreate from "./pages/salescreate";//
import SalesMobile from "./pages/salesmobile"; // quiero que si es dispotivo abra este 
import Stampeds from "./pages/stampeds";
import Stock from "./pages/stock";
import PaymenMethods from "./pages/paymentmethods";
import Prices from "./pages/prices";
import PricesCreate from "./pages/pricescreate";
import PricesList from "./pages/priceslist";
import PotentialClients from "./pages/potentialclients";
import SalePDF from "./pages/salepdf";
import SalePrint from "./pages/saleprint";
import SalePrintMo from "./pages/saleprintmo";
import SalePrintTopFit from "./pages/saleprint-topfit";
import SalePrintSantaAna from "./pages/saleprint-santa-ana";
import PricePDF from "./pages/pricepdf";
import PricePrint from "./pages/priceprint";
import PrivateRoute from "./components/privateroute";
import PaymentOrdersViewer from "./pages/paymentordersviewer";
import Providers from "./pages/providers";
import ProviderAccountState from "./pages/provideraccountstate";
import ProviderAccountStatePrint from "./pages/provideraccountstateprint";
import ProviderPayments from "./pages/providerpayments";
import ProviderPaymentsViewer from "./pages/providerpaymentsviewer";
import Purchases from "./pages/purchases";
import PurchasesCreate from "./pages/purchasescreate";
import PurchaseCreditNotes from "./pages/purchasecreditnotes";
import PurchasesReport from "./pages/purchasesreport";
import RG90 from "./pages/rg90";
import Roles from "./pages/roles";
import SaleCreditNotes from "./pages/salecreditnotes";
import SalesRanking from "./pages/salesranking";
import SalesReport from "./pages/salesreport";
import Support from "./pages/support";
import SupportCreate from "./pages/supportcreate";
import SupportDetail from "./pages/support";
import Users from "./pages/users";
import Zones from "./pages/zones";
import Backlogs from "./pages/backlogs";
import Divisions from "./pages/divisions";
import Menus from "./pages/menus";
import Submenus from "./pages/submenus";
import Subscriptions from "./pages/subscriptions";
import ConfImpresoras from "./pages/confimpresoras";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import IssuedChecks from "./pages/issuedchecks";
import AdjustmentGroups from "./pages/adjustmentGroups"; 
import AdjustmentGroupsCreate from "./pages/adjustmentGroupsCreate"; 
import Transference from "./pages/stockTrans";
import SalePDFTopFit from "./pages/salepdftopfit";
import BarConfig from "./pages/tables";
import TablesPage from "./pages/tablesPage";
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const App = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Actualiza el estado según el tamaño de la ventana
    };

    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<PrivateRoute />} path="/">
            <Route element={<Accounts />} path="/cuentas" />
            <Route element={<Accounts />} path="/cuentas/:page" />

            <Route element={<AccounTypes />} path="/tiposdecuenta" />
            <Route element={<AccounTypes />} path="/tiposdecuenta/:page" />

            <Route element={<Banks />} path="/bancos" />
            <Route element={<Banks />} path="/bancos/:page" />

            <Route element={<BankDeposits />} path="/bancdeps" />
            <Route element={<BankDeposits />} path="/bancdeps/:page" />

            <Route element={<BankDeposiTypes />} path="/tiposdedepositos" />
            <Route element={<BankDeposiTypes />} path="/tiposdedepositos/:page" />

            <Route element={<BankStatement />} path="/extractobancario" />

            <Route element={<Boxes />} path="/cajas" />
            <Route element={<Boxes />} path="/cajas/:page" />

            <Route element={<BoxOpenings />} path="/aperturasdeca" />
            <Route element={<BoxOpenings />} path="/aperturasdeca/:page" />

            <Route element={<Branches />} path="/sucursales" />
            <Route element={<Branches />} path="/sucursales/:page" />

            <Route element={<Brands />} path="/marcas" />
            <Route element={<Brands />} path="/marcas/:page" />

            <Route element={<Cities />} path="/ciudades" />
            <Route element={<Cities />} path="/ciudades/:page" />

            <Route element={<CheckStatements />} path="/estadosdecheq" />
            <Route element={<CheckStatements />} path="/estadosdecheq/:page" />

            <Route element={<ClientAccountState />} path="/estadodecuenta" />
            <Route
              element={<ClientAccountStatePrint />}
              path="/estadodecuentaimprimir"
            />

            <Route element={<Clients />} path="/clientes" />
            <Route element={<Clients />} path="/clientes/:page" />

            <Route
              element={<CollectionPDF />}
              path="/cobranzasrecibo/:collectionId"
            />

            <Route
              element={<CollectionPrint />}
              path="/cobranzasimprimir/:collectionId"
            />

            <Route element={<Collections />} path="/cobranzas" />
            <Route element={<Collections />} path="/cobranzas/:page" />

            <Route element={<CollectionsReport />} path="/cobrep" />
            <Route element={<CollectionsViewer />} path="/cobros" />

            <Route element={<Companies />} path="/empresas" />
            <Route element={<Companies />} path="/empresas/:page" />

            <Route element={<Contacts />} path="/contactos" />
            <Route element={<Contacts />} path="/contacts/:page" />

            <Route element={<Contracts />} path="/contratos" />
            <Route element={<Contracts />} path="/contratos/:page" />

            <Route element={<Currencies />} path="/monedas" />
            <Route element={<Currencies />} path="/monedas/:page" />

            <Route element={<Dashboard />} path="/" />
            
            <Route element={<DataOrigins />} path="/origenesdedatos" />
            <Route element={<DataOrigins />} path="/origenesdedatos/:page" />

            <Route element={<Deposits />} path="/depositos" />
            <Route element={<Deposits />} path="/depositos/:page" />

            <Route element={<Groups />} path="/grupos" />
            <Route element={<Groups />} path="/grupos/:page" />

            <Route element={<IssuedChecks />} path="/cheqemit" />
            <Route element={<IssuedChecks />} path="/cheqemit/:page" />

            <Route element={<Items />} path="/articulos" />
            <Route element={<Items />} path="/articulos/:page" />

            <Route element={<ItemTypes />} path="/tiposdearticulos" />
            <Route element={<ItemTypes />} path="/tiposdearticulos/:page" />

            <Route element={<Menus />} path="/mens" />
            <Route element={<Menus />} path="/mens/:page" />

            <Route element={<MovemenTypes />} path="/tiposdemovimientos" />
            <Route
              element={<MovemenTypes />}
              path="/tiposdemovimientos/:page"
            />

            <Route element={<MeasurementUnits />} path="/unidadesdemedida" />
            <Route
              element={<MeasurementUnits />}
              path="/unidadesdemedida/:page"
            />

            <Route element={<Movements />} path="/movimientos" />
            <Route element={<Movements />} path="/movimientos/:page" />

            <Route element={<PaymenMethods />} path="/formasdepago" />
            <Route element={<PaymenMethods />} path="/formasdepago/:page" />

            <Route element={<PaymentOrdersViewer />} path="/ordenesdepago" />
            <Route
              element={<PaymentOrdersViewer />}
              path="/ordenesdepago/:page"
            />

            <Route element={<PotentialClients />} path="/potencialesclien" />
            <Route
              element={<PotentialClients />}
              path="/potencialesclien/:page"
            />

            <Route element={<Prices />} path="/cotizaciones" />
            <Route element={<Prices />} path="/cotizaciones/:page" />

            <Route element={<PricesCreate />} path="/cotizar" />

            <Route element={<PricesList />} path="/listadeprecios" />
            <Route element={<PricesList />} path="/listadeprecios/:page" />

            <Route element={<PricePDF />} path="/cotizacionpdf/:priceId" />

            <Route
              element={<PricePrint />}
              path="/imprimircotizacion/:priceId"
            />

            <Route element={<Providers />} path="/provs" />
            <Route element={<Providers />} path="/provs/:page" />

            <Route element={<ProviderAccountState />} path="/proveedoresestado" />
            <Route
              element={<ProviderAccountStatePrint />}
              path="/proveedoresestadoimprimir"
            />

            <Route element={<ProviderPayments />} path="/pagoproveedores" />

            <Route element={<ProviderPaymentsViewer />} path="/vispagosprovs" />
            <Route
              element={<ProviderPaymentsViewer />}
              path="/vispagosprovs/:page"
            />

            <Route element={<Providers />} path="/proveeds" />
            <Route element={<Providers />} path="/proveeds/:page" />

            <Route element={<Purchases />} path="/visor" />
            <Route element={<Purchases />} path="/visor/:page" />

            <Route element={<PurchasesCreate />} path="/compras" />

            <Route element={<PurchaseCreditNotes />} path="/notasdecredito" />
            <Route
              element={<PurchaseCreditNotes />}
              path="/notasdecredito/:page"
            />

            <Route element={<PurchasesReport />} path="/repcomp" />

            <Route element={<ReceivedChecks />} path="/cheqrec" />
            <Route element={<ReceivedChecks />} path="/cheqrec/:page" />

            <Route element={<Roles />} path="/roles" />
            <Route element={<Roles />} path="/roles/:page" />

            <Route element={<RG90 />} path="/rg90" />

            <Route element={<SaleCreditNotes />} path="/crednotes" />
            <Route element={<SaleCreditNotes />} path="/crednotes/:page" />

            <Route element={<SalesRanking />} path="/rank" />
            <Route element={<SalesReport />} path="/reporte" />

            <Route element={<SupportCreate />} path="/registrar" />

            <Route element={<SaleConditions />} path="/condicionesdeventa" />
            <Route
              element={<SaleConditions />}
              path="/condicionesdeventa/:page"
            />

            <Route element={<SalePDF />} path="/facturapdf/:saleId" />
            <Route element={<SalePDFTopFit />} path="/facturapdftopfit/:saleId" />
            <Route element={<SalePrint />} path="/imprimirfactura/:saleId" />
            <Route element={<SalePrintMo />} path="/imprimirfacturaMo/:saleId" />

            <Route element={<SalePrintTopFit />} path="/imprimirfacturaTop/:saleId" />
            <Route element={<SalePrintSantaAna />} path="/imprimirfacturasantaana/:saleId" />

            <Route element={<Support />} path="/soporte" />

            <Route element={<SupportDetail />} path="/seguimiento" />
            <Route element={<SupportDetail />} path="/seguimiento/:page" />

            <Route element={<Submenus />} path="/submenus" />
            <Route element={<Submenus />} path="/submenus/:page" />

            <Route element={<Stock />} path="/stock" />
            <Route element={<Stock />} path="/stock/:page" />

            <Route element={<Stampeds />} path="/timbrados" />
            <Route element={<Stampeds />} path="/timbrados/:page" />

            <Route element={<Sales />} path="/ventas" />
            <Route element={<Sales />} path="/ventas/:page" />

            <Route element={isMobile ? <SalesMobile /> : <SalesCreate />} path="/facturacion" />

            <Route element={<Users />} path="/usuarios" />
            <Route element={<Users />} path="/usuarios/:page" />

            <Route element={<Zones />} path="/zonas" />
            <Route element={<Zones />} path="/zonas/:page" />
            <Route element={<Divisions />} path="/division" />
            <Route element={<Divisions />} path="/division/:page" />
            <Route element={<Subscriptions />} path="/subcriptions" />
            <Route element={<Subscriptions />} path="/subcriptions/:page" />
            <Route element={<ConfImpresoras />} path="/confimp" />
            <Route element={<Backlogs />} path="/backlogs" />
            <Route element={<AdjustmentGroups />} path="/gruposdeajustes" />
            <Route element={<AdjustmentGroupsCreate />} path="/crearajustestock" />
            <Route element={<Transference />} path="/transferencias" />
            <Route element={<BarConfig />} path="/mesas" />
            <Route element={<TablesPage />} path="/mesas-ventas" />
         
          </Route>
          
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
