'use client';
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Search, User, X, Check, Edit, CreditCard } from 'lucide-react';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { axiosAuth } from "../utils/axios";
import Layout from "../layout";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { RootState } from "../types";

interface Product {
  id: string;
  code: string;
  name: string;
  price: number;
  iva: string;
}

interface SaleItem extends Product {
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

interface Customer {
  id: string;
  name: string;
  ruc: string;
}

interface PaymentMethod {
  type: 'efectivo' | 'cheque' | 'transferencia';
  amount: number;
}

const SalesSimulator = () => {
  const user = useSelector((state: RootState) => state.main.user);
  const [isSaving, setIsSaving] = useState(false);
  const [sellerId] = useState(user.id);
  const [boxId] = useState(user.boxId);
  const [currencyId] = useState(1);
  const [branchId] = useState(user.branchId);
  const [depositId] = useState(1);
  const [companyId] = useState(user.companyId);
  const [number, setNumber] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<Customer[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [saleItems, setSaleItems] = useState<SaleItem[]>([]);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [isQuantityDialogOpen, setIsQuantityDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedSaleItem, setSelectedSaleItem] = useState<SaleItem | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [paymentType, setPaymentType] = useState<string>("contado");
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [currentPaymentType, setCurrentPaymentType] = useState<'efectivo' | 'cheque' | 'transferencia'>('efectivo');
  const [currentPaymentAmount, setCurrentPaymentAmount] = useState(0);

  const total = saleItems.reduce((sum, item) => sum + item.totalPrice, 0);
  const formattedTotal = new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(total);
  const totalPaid = paymentMethods.reduce((sum, method) => sum + method.amount, 0);
  const remainingAmount = total - totalPaid;

  useEffect(() => {
    searchTerm ? handleSearchProducts(searchTerm) : setFilteredProducts([]);
  }, [searchTerm]);

  useEffect(() => {
    customerSearchTerm ? handleSearchCustomers(customerSearchTerm) : setClients([]);
  }, [customerSearchTerm]);

  const handleProductSelect = (product: Product) => {
    const newItem: SaleItem = {
      ...product,
      quantity: 1,
      unitPrice: product.price,
      totalPrice: product.price,
      iva: product.iva,
    };
    setSaleItems([...saleItems, newItem]);
    setIsProductDialogOpen(false);
    setSearchTerm('');
  };

  const handleSearchProducts = async (search: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosAuth.get(`/searchItems?search=${search}&page=1&boole=true`);
      setFilteredProducts(response.data.items.rows);
    } catch {
      setError("Error al cargar los productos. Intenta nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchCustomers = async (search: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosAuth.get(`/searchClients?search=${search}`);
      setClients(response.data.clients.rows);
    } catch {
      setError("Error al cargar los clientes. Intenta nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditSaleItem = (item: SaleItem) => {
    setSelectedSaleItem(item);
    setSelectedProduct(item);
    setQuantity(item.quantity);
    setUnitPrice(item.unitPrice);
    setIsQuantityDialogOpen(true);
  };

  const addOrUpdateProduct = () => {
    if (selectedProduct) {
      if (selectedSaleItem) {
        const updatedItems = saleItems.map(item =>
          item.id === selectedSaleItem.id
            ? { ...item, quantity, unitPrice, totalPrice: quantity * unitPrice }
            : item
        );
        setSaleItems(updatedItems);
      }
      setIsQuantityDialogOpen(false);
      setSearchTerm('');
      setSelectedProduct(null);
      setSelectedSaleItem(null);
      setQuantity(1);
      setUnitPrice(0);
    }
  };

  const removeItem = (id: string) => {
    setSaleItems(items => items.filter(item => item.id !== id));
  };

  const handleAddPaymentMethod = () => {
    if (currentPaymentAmount <= 0) {
      alert('El monto debe ser mayor a 0');
      return;
    }
    if (totalPaid + currentPaymentAmount > total) {
      alert('El monto total de los pagos no puede superar el total de la venta');
      return;
    }

    setPaymentMethods([...paymentMethods, {
      type: currentPaymentType,
      amount: currentPaymentAmount
    }]);
    setCurrentPaymentAmount(0);
  };

  const removePaymentMethod = (index: number) => {
    setPaymentMethods(methods => methods.filter((_, i) => i !== index));
  };

  const handleCompleteSale = async () => {
    if (!selectedCustomer) {
      alert('Debe seleccionar un cliente.');
      return;
    }
    if (saleItems.length === 0) {
      alert('Debe agregar al menos un producto.');
      return;
    }
    if (remainingAmount > 0) {
      setIsPaymentDialogOpen(true);
      return;
    }
    if (isSaving) return;
    setIsSaving(true);

    const salesDetails = saleItems.map(item => ({
      id: item.id,
      qty: item.quantity,
      name: item.name,
      price: item.unitPrice,
      subtotal: item.totalPrice,
      iva: item.iva,
    }));

    const dues = paymentType === 'credito'
      ? Array.from({ length: 3 }, (_, i) => ({
        dueDate: new Date(new Date().setMonth(new Date().getMonth() + (i + 1))).toISOString().split('T')[0],
        amount: total / 3,
      }))
      : [];

    let generalNumber = localStorage.getItem("generalNumber");
    const saleData = {
      clientId: selectedCustomer.id,
      sellerId,
      boxId,
      currencyId,
      branchId,
      depositId,
      companyId,
      number: generalNumber,
      saleconditionId: 1,
      deleted: 0,
      salesdetail: salesDetails,
      date: new Date().toISOString().split('T')[0],
      totalAmount: total,
      payments: paymentMethods.map(method => ({
        type: method.type,
        amount: method.amount
      })),
      dues,
    };

    try {
      const response = await axiosAuth.post('/sales', saleData);
      if (response.data.error) {
        alert('Error al crear la venta. Intenta nuevamente.');
      } else {
        setSaleItems([]);
        setSelectedCustomer(null);
        setPaymentMethods([]);
        setIsSuccessDialogOpen(true);
      }
    } catch {
      alert('Ocurrió un error al intentar crear la venta.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Layout>
      <div className="max-w-full mx-auto bg-white h-screen flex flex-col">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />

        {/* Header - Fixed */}
        <div className="p-4 space-y-2 bg-white">
          <div className="flex items-center justify-between text-base">
            <div className="flex items-center gap-2">

            <Dialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-2xl">Métodos de Pago</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="flex justify-between text-xl">
              <span>Total a pagar:</span>
              <span>{new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(total)}</span>
            </div>
            <div className="flex justify-between text-xl text-green-600">
              <span>Total pagado:</span>
              <span>{new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(totalPaid)}</span>
            </div>
            <div className="flex justify-between text-xl text-red-600">
              <span>Restante:</span>
              <span>{new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(remainingAmount)}</span>
            </div>

            <div className="space-y-2">
              <h3 className="text-xl font-medium">Pagos registrados:</h3>
              {paymentMethods.map((method, index) => (
                <div key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded">
                  <div>
                    <span className="capitalize">{method.type}</span>
                    <span className="ml-2">
                      {new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(method.amount)}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => removePaymentMethod(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="h-5 w-5" />
                  </Button>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <Select value={currentPaymentType} onValueChange={(value: 'efectivo' | 'cheque' | 'transferencia') => setCurrentPaymentType(value)}>
                <SelectTrigger className="w-full text-xl">
                  <SelectValue placeholder="Método de pago" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="efectivo" className="!text-xl">Efectivo</SelectItem>
                  <SelectItem value="cheque" className="!text-xl">Cheque</SelectItem>
                  <SelectItem value="transferencia" className="!text-xl">Transferencia</SelectItem>
                </SelectContent>
              </Select>

              <div className="space-y-2">
                <label className="text-xl font-medium">Monto</label>
                <Input
                  type="number"
                  value={currentPaymentAmount}
                  onChange={(e) => setCurrentPaymentAmount(Number(e.target.value))}
                  className="text-xl"
                />
              </div>

              <Button 
                onClick={handleAddPaymentMethod}
                className="w-full text-xl"
                disabled={currentPaymentAmount <= 0 || totalPaid + currentPaymentAmount > total}
              >
                Agregar Pago
              </Button>
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="ghost"
              onClick={() => setIsPaymentDialogOpen(false)}
              className="text-xl"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (remainingAmount === 0) {
                  setIsPaymentDialogOpen(false);
                  handleCompleteSale();
                } else {
                  alert('Debe cubrir el monto total de la venta');
                }
              }}
              className="text-xl"
              disabled={remainingAmount > 0}
            >
              Finalizar Venta
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
              <Dialog open={isCustomerDialogOpen} onOpenChange={setIsCustomerDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <User className="h-8 w-8" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Seleccionar Cliente</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <Input
                      type="text"
                      placeholder="Buscar clientes..."
                      value={customerSearchTerm}
                      onChange={(e) => setCustomerSearchTerm(e.target.value)}
                      className="mb-4 text-xl"
                    />
                    {loading && <div className="text-center text-xl">Cargando...</div>}
                    {error && <div className="text-center text-xl text-red-500">{error}</div>}
                    {clients.map(customer => (
                      <div
                        key={customer.id}
                        className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setSelectedCustomer(customer);
                          setIsCustomerDialogOpen(false);
                          setCustomerSearchTerm('');
                        }}
                      >
                        <div className="text-xl">{customer.name}</div>
                        <div className="text-base text-gray-500">RUC: {customer.ruc}</div>
                      </div>
                    ))}
                  </div>
                  <DialogFooter>
                    <Button variant="ghost" onClick={() => setIsCustomerDialogOpen(false)} className="text-xl">
                      Cerrar
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              <Dialog open={isProductDialogOpen} onOpenChange={setIsProductDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Search className="h-8 w-8" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Buscar Producto</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <Input
                      type="text"
                      placeholder="Buscar productos..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-4 text-xl"
                    />
                    {loading && <div className="text-center text-xl">Cargando...</div>}
                    {error && <div className="text-center text-xl text-red-500">{error}</div>}
                    {filteredProducts.map(product => (
                      <div
                        key={product.id}
                        className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleProductSelect(product)}
                      >
                        <div>
                          <div className="text-xl">{product.name}</div>
                          <div className="text-base text-gray-500">{product.code}</div>
                        </div>
                        <span className="text-xl">
                          {new Intl.NumberFormat('es-PY', {
                            style: 'decimal',
                            maximumFractionDigits: 0
                          }).format(product.price)}
                        </span>
                      </div>
                    ))}
                  </div>
                  <DialogFooter>
                    <Button variant="ghost" onClick={() => setIsProductDialogOpen(false)} className="text-xl">
                      Cerrar
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
            <Select value={paymentType} onValueChange={setPaymentType}>
              <SelectTrigger className="w-[140px] text-2xl">
                <SelectValue placeholder="Tipo de pago" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="contado" className="!text-2xl">Contado</SelectItem>
                <SelectItem value="credito" className="!text-2xl">Crédito</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Cliente seleccionado - Fixed */}
        {selectedCustomer && (
          <div className="px-4 py-2 mx-3 bg-gray-100 rounded-md">
            <div className="flex justify-between items-center">
              <div>
                <div className="font-medium text-xl">{selectedCustomer.name}</div>
                <div className="text-base text-gray-500">RUC: {selectedCustomer.ruc}</div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSelectedCustomer(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
          </div>
        )}

        {/* Lista de items - Scrollable */}
        <div className="flex-1 p-4 overflow-y-auto">
          {saleItems.map((item, index) => (
            <div key={item.id} className="flex items-center justify-between py-2">
              <div className="flex items-start gap-2">
                <span className="bg-gray-100 text-gray-600 w-6 h-6 flex items-center justify-center rounded text-xl">
                  {index + 1}
                </span>
                <div>
                  <div className="font-medium">{item.name}</div>
                  <div className="text-base text-gray-500">
                    {item.code} - {item.quantity}x @ {new Intl.NumberFormat('es-PY', {
                      style: 'decimal',
                      maximumFractionDigits: 0
                    }).format(item.unitPrice)}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="!text-xl">
                  {new Intl.NumberFormat('es-PY', {
                    style: 'decimal',
                    maximumFractionDigits: 0
                  }).format(item.totalPrice)}
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleEditSaleItem(item)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Edit className="h-5 w-5" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => removeItem(item.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className="fixed bottom-0 left-0 w-full p-4 bg-white border-t border-gray-200">
          <div className="flex justify-between items-center mb-2">
            <span className="!text-1xl text-gray-600 mx-4">IVA:</span>
            <span className="!text-2xl text-gray-600 mx-4">
              {new Intl.NumberFormat('es-PY', {
                style: 'decimal',
                maximumFractionDigits: 0
              }).format(Math.round(total / 11))}
            </span>
          </div>
          <Button
  variant="primary"
  size="lg"
  className={`w-full bg-[#111e40] text-[#ffffff] py-8 px-4 rounded-full text-3xl font-bold shadow-lg hover:shadow-2xl transition duration-300 ease-in-out ${
    isSaving ? 'opacity-50 cursor-not-allowed' : ''
  }`}
  onClick={handleCompleteSale}
  disabled={isSaving}
>
  PYG {formattedTotal}
</Button>
        </div>

        {/* Quantity Dialog */}
        <Dialog open={isQuantityDialogOpen} onOpenChange={setIsQuantityDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {selectedSaleItem ? 'Editar Producto' : 'Detalles del Producto'}
              </DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="space-y-2">
                <label className="text-xl font-medium">
                  {selectedProduct?.name}
                </label>
                <div className="text-base text-gray-500">
                  {selectedProduct?.code}
                </div>
              </div>
              <div className="grid gap-2">
                <label className="text-xl font-medium">
                  Cantidad
                </label>
                <Input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                  min="1"
                  className="text-xl"
                />
              </div>
              <div className="grid gap-2">
                <label className="text-xl font-medium">
                  Precio Unitario
                </label>
                <Input
                  type="number"
                  value={unitPrice}
                  onChange={(e) => setUnitPrice(Math.max(0, parseInt(e.target.value) || 0))}
                  min="0"
                  className="text-xl"
                />
              </div>
              <div className="flex justify-between font-medium text-xl">
                <span>Total:</span>
                <span>
                  {new Intl.NumberFormat('es-PY', {
                    style: 'decimal',
                    maximumFractionDigits: 0
                  }).format(quantity * unitPrice)}
                </span>
              </div>
            </div>
            <DialogFooter>
              <Button variant="ghost" onClick={() => setIsQuantityDialogOpen(false)} className="text-xl">
                Cancelar
              </Button>
              <Button onClick={addOrUpdateProduct} className="text-xl">
                {selectedSaleItem ? 'Actualizar' : 'Agregar'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Success Dialog */}
        <Dialog open={isSuccessDialogOpen} onOpenChange={setIsSuccessDialogOpen}>
          <DialogContent className="flex items-center justify-center h-screen bg-[#111e40]">
            <div className="text-center text-white">
              <Check className="mx-auto mb-4 w-16 h-16 text-[#ffffff]" />
              <h2 className="text-2xl text-[#ffffff] font-bold mb-6">¡Venta Guardada!</h2>
              <div className="flex justify-center gap-4">
                <Button variant="outline" className="bg-white text-[#111e40]" onClick={() => setIsSuccessDialogOpen(false)}>
                  Atrás
                </Button>
                <Button className="bg-white text-[#111e40]">Imprimir</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </Layout>

  )
}
export default SalesSimulator;