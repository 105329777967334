import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosAuth } from "../utils/axios";
import logo from "../assets/img/logo-big.jpg";
import { numberWithCommas, numeroALetras } from "../utils/constants";
import { setToken, setUser } from "../store/slices/mainSlice";
import { usePDF } from "react-to-pdf";
import "../assets/css/documents.css";

const SalePDF = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saleId } = useParams();
  const [sale, setSale] = useState([]);
  const [total, setTotal] = useState(0);
  const [saledate, setSaleDate] = useState("");
  const date = new Date().toLocaleDateString();

  const { toPDF, targetRef } = usePDF({
    filename: "FACTURA_" + date + ".pdf",
  });

  const fetchSale = async () => {
    let saleR = await axiosAuth("/sales/" + saleId);

    if (saleR.data.error) {
      if (saleR.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let totalSum = 0;

    saleR.data.sale.salesdetails.map((detail) => {
      totalSum += detail.quantity * detail.price;
    });

    while (String(saleR.data.sale.number).length < 7) {
      saleR.data.sale.number = "0" + String(saleR.data.sale.number);
    }

    if (saleR.data.sale.salesdetails.length < 5) {
      let toAdd = 6 - saleR.data.sale.salesdetails.length;
      let toMap = [...Array(toAdd).keys()];

      toMap.map((itm) => {
        saleR.data.sale.salesdetails.push({
          quantity: "",
          item: { name: "" },
          price: "",
        });
      });
    }

    setTotal(totalSum);
    setSale(saleR.data.sale);

    let dateFormat = new Date(saleR.data.sale.createdAt);
    //dateFormat.setDate(dateFormat.getDate() + 1);

    let months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",

    ];
    let day =
      String(dateFormat.getDate()).length == 1
        ? "0" + dateFormat.getDate()
        : dateFormat.getDate();
    setSaleDate(
      day + " " + months[dateFormat.getMonth()] + " " + dateFormat.getFullYear()
    );

    // to upload again

    setTimeout(function () {
      toPDF();
      setTimeout(function () {
        window.close();
      }, 1500);
    }, 1500);
  };

  useEffect(function () {
    fetchSale();
  }, []);

  return (
    <>
      <h1
        className="absolute top-44 left-72"
        style={{
          zIndex: 9,
          color: "rgba(0,0,0,0.3)",
          transform: "rotate(-25deg)",
          fontSize: "62px",
          fontWeight: "bold",
        }}
      >
        ORIGINAL
      </h1>
      <div
        style={{
          fontFamily: "Arial !important",
          width: "860px",
          height: "400px",
        }}
        ref={targetRef}
      >
        <p
          className="!text-[5px]"
          style={{
            zIndex: 99,
            position: "relative",
            left: -415,
            top: -43,
            transform: "rotate(-90deg)",
          }}
        >
          Habilitacion Nro. 2425 - Grafica CANTERO S.R.L - R.U.C. 80103548-1
          Tel/Fax: (0294) 220 329 - E-mail: canterogabriel521@gmail.com
        </p>
        <p
          className="!text-[5px]"
          style={{
            zIndex: 99,
            top: -30,
            left: -408,
            position: "relative",
            transform: "rotate(-90deg)",
            fontSize: "5px !important",
          }}
        >
          Itaugua - Ruta Mariscal Estigarribia e/ Carlos Antonio Lopez y Gaspar
          Rodriguez de Francia Nro. 463 - 1000 FACTURAS 50X3 DEL [7201 - 8200]
          ENERO/24
        </p>
        <div
          className="bg-white w-[20cm] h-[10.2cm] p-2 ml-5 relative mt-5"
          style={{ transform: "scale(0.98)" }}
        >
          <div className="flex">
            <div
              className="w-[19%] text-[10px] text-center"
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.5)",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
                borderRight: "1px solid rgba(0,0,0,0.5)",
                borderTop: "1px solid rgba(0,0,0,0.5)",
              }}
            >
              <img
                src={logo}
                className="w-24"
                style={{ margin: "10px auto" }}
              />
              <h2 className="text-lg font-bold">OCUS S.A.</h2>
              <h3 className="mb-4">
                Análisis, diseño y programación <br />
                de sistemas informáticos
              </h3>
              <p style={{ fontSize: "10px" }}>Vicente Paul c/ Carmen Peña</p>
              <p style={{ fontSize: "10px" }}>Tel.: +595 984 317 179</p>
              <p style={{ fontSize: "10px" }}>Itauguá - Paraguay</p>
            </div>
            <div
              className="w-full flex flex-col text-[10px] text-center"
              style={{ borderTop: "1px solid rgba(0,0,0,0.7)" }}
            >
              <div className="flex">
                <div className="w-[69.8%] h-[100px] text-[9px] text-left pb-3 pt-3 pl-3 flex flex-row justify-between">
                  <div className="w-[60%]">
                    <p>
                      <b>FECHA</b>
                      <b className="ml-5">:</b> <i>{saledate}</i>
                    </p>
                    <p className="w-full">
                      <b>SEÑORES</b>
                      <b className="ml-2">:</b> <i>{sale?.client?.name}</i>
                    </p>
                    <p>
                      <b>RUC</b>
                      <b className="ml-8">:</b> <i>{sale?.client?.ruc}</i>
                    </p>
                    <p>
                      <b>DIRECCION</b>
                      <b>:</b> <i>{sale?.client?.address}</i>
                    </p>
                  </div>
                  <div className="mr-5 w-[30%]">
                    <p>
                      <b>CONDICION:</b>{" "}
                      <i>{sale?.salecondition?.description}</i>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[30%] text-[10px] text-right pr-3 justify-end items-center flex"
                  style={{
                    borderLeft: "1px solid rgba(0,0,0,0.5)",
                    borderRight: "1px solid rgba(0,0,0,0.5)",
                  }}
                >
                  <div className="text-left relative top-[-10px]">
                    <div>
                      <p style={{ lineHeight: "11px" }}>
                        <b>RUC: 80090280-7</b>
                      </p>
                    </div>
                    <div>
                      <p style={{ lineHeight: "11px" }}>
                        <b>Timbrado N°. 16938716</b>
                      </p>
                    </div>
                    <div>
                      <p style={{ lineHeight: "11px" }}>
                        <b>Fecha Inicio Vigencia 05/01/2024</b>
                      </p>
                    </div>
                    <div>
                      <p style={{ lineHeight: "11px" }}>
                        <b>Fecha Fin Vigencia 31/01/2025</b>
                      </p>
                    </div>
                    <div>
                      <p style={{ lineHeight: "12px", marginTop: 2 }}>
                        FACTURA ORIGINAL
                      </p>
                    </div>
                  </div>

                  <p className="absolute top-[75px] right-[15px]">
                    <b className="text-[7px]">
                      {sale.expeditionPoint} {sale.number}
                    </b>
                  </p>
                  <p className="text-[16px] absolute top-[80px] right-[15px]">
                    <b>
                      N° {sale.expeditionPoint}-{" "}
                      <span className="text-lg">{sale.number}</span>
                    </b>
                  </p>
                </div>
              </div>

              <div className="flex">
                <div
                  className="w-[70%] text-[11px] text-left pb-1 pt-1 pl-1 flex flex-row"
                  style={{
                    borderTop: "1px solid rgba(0,0,0,0.5)",
                    borderBottom: "1px solid rgba(0,0,0,0.5)",
                  }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[10%] text-[9px] text-center">
                      <b>CANT.</b>
                    </p>
                    <p className="w-[75%] text-[9px] text-center">
                      <b>DESCRIPCION</b>
                    </p>
                    <p className="w-[15%] text-[9px] text-center">
                      <b>PR. UNITARIO</b>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[29.8%] text-[10px] text-right"
                  style={{
                    borderTop: "1px solid  rgba(0,0,0,0.5)",
                    borderBottom: "1px solid  rgba(0,0,0,0.5)",
                    borderRight: "1px solid  rgba(0,0,0,0.5)",
                  }}
                >
                  <div className="w-full flex flex-row justify-around">
                    <p className="w-[33%] text-[9px] text-center pt-1">
                      <b>EXENTAS</b>
                    </p>
                    <p className="w-[33%] text-[9px] text-center pt-1">
                      <b>5%</b>
                    </p>
                    <p className="w-[33%] text-[9px] text-center pt-1">
                      <b>10%</b>
                    </p>
                  </div>
                </div>
              </div>

              {sale?.salesdetails?.map((saledetail) => (
                <div className="flex">
                  <div
                    className="w-[70%] text-[10px] text-left flex flex-row"
                    style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                  >
                    <div className="w-full flex flex-row">
                      <p className="w-[10%] text-center">
                        {saledetail.quantity}
                      </p>
                      <p
                        className="w-[75%] text-left pl-1"
                        style={{
                          borderLeft: "1px solid rgba(0,0,0,0.5)",
                          borderRight: "1px solid rgba(0,0,0,0.5)",
                        }}
                      >
                        {saledetail.item.name}
                      </p>
                      <p className="w-[15%] text-center">
                        {numberWithCommas(saledetail.price)}
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-[29.8%] text-[10px] text-right"
                    style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                  >
                    <div className="w-full flex flex-row justify-around">
                      <p className="w-[33%] text-center pt-1">
                        <b></b>
                      </p>
                      <p
                        className="w-[33%] text-center pt-1"
                        style={{
                          borderLeft: "1px solid rgba(0,0,0,0.5)",
                          borderRight: "1px solid rgba(0,0,0,0.5)",
                        }}
                      >
                        <b></b>
                      </p>
                      <p
                        className="w-[33%] text-[10px] text-center"
                        style={{
                          color:
                            saledetail.price * saledetail.quantity == 0
                              ? "transparent"
                              : "black",
                        }}
                      >
                        {numberWithCommas(
                          saledetail.price * saledetail.quantity
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className="flex"
                style={{
                  borderTop: "1px solid rgba(0,0,0,0.5)",
                  borderBottom: "1px solid rgba(0,0,0,0.5)",
                }}
              >
                <div
                  className="w-[70%] text-[11px] text-left flex flex-row"
                  style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                >
                  <div
                    className="w-full flex flex-row"
                    style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                  >
                    <p className="w-[100%] text-[9px] text-left p-1">
                      <b>SUBTOTALES</b>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[29.8%] text-[10px] text-right"
                  style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                >
                  <div className="w-full flex flex-row justify-around h-[100%]">
                    <p className="w-[33%] text-center pt-1">
                      <b></b>
                    </p>
                    <p
                      className="w-[33%] text-center pt-1"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,0.5)",
                        borderRight: "1px solid rgba(0,0,0,0.5)",
                      }}
                    >
                      <b></b>
                    </p>
                    <p className="w-[32.9%] text-[10px] text-center pt-1">
                      {numberWithCommas(total)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{}}>
                <div className="w-[70%] text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left text-[9px] p-1">
                      <b className="text-[9px]">TOTAL A PAGAR: </b>
                      {numeroALetras(total, {
                        plural: "GUARANIES",
                        singular: "GUARANI",
                        centPlural: "CENTAVOS",
                        centSingular: "CENTAVO",
                      })}
                    </p>
                  </div>
                </div>
                <div
                  className="w-[29.8%] text-[10px] text-right"
                  style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                >
                  <div className="w-full flex flex-row justify-end h-[100%]">
                    <p className="w-[33%] text-center pt-1">
                      <b>{numberWithCommas(total)}</b>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="flex"
                style={{
                  width: "99.8%",
                  borderTop: "1px solid rgba(0,0,0,0.5)",
                  borderRight: "1px solid rgba(0,0,0,0.5)",
                  borderBottom: "1px solid rgba(0,0,0,0.5)",
                }}
              >
                <div className="w-full text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[33%] text-left p-1">
                      <b className="text-[9px]">LIQUIDACION DEL IVA</b>
                    </p>
                    <p className="w-[20%] text-left p-1 text-[9px]">
                      <b>5%:</b>
                      {numberWithCommas(sale.vat5)}
                    </p>
                    <p className="w-[20%] text-left p-1 text-[9px]">
                      <b>10%:</b> {numberWithCommas(sale.vat10)}
                    </p>
                    <p className="w-[20%] text-left p-1 text-[9px]">
                      <b>TOTAL IVA:</b> {numberWithCommas(sale.vat10)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-16 text-[10px] p-2 w-[99.9%]"
            style={{
              borderBottom: "1px solid rgba(0,0,0,0.5)",
              borderRight: "1px solid rgba(0,0,0,0.5)",
              borderLeft: "1px solid rgba(0,0,0,0.5)",
            }}
          >
            <h3 className="inline-block w-[25%] text-left mt-8">FECHA:</h3>
            <h3 className="inline-block w-[25%] text-left mt-8">FIRMA:</h3>
            <h3 className="inline-block w-[23%] text-left mt-8">ACLARACION:</h3>
            <h3 className="inline-block w-[10%] text-left mt-8 ml-24">C.I.:</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalePDF;
